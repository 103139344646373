const pkCities = [
  {
    city: "Karachi", 
    "lat": "24.8600", 
    "lng": "67.0100", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "admin", 
    "population": "14835000", 
    "population_proper": "14835000"
  }, 
  {
    city: "Lahore", 
    "lat": "31.5497", 
    "lng": "74.3436", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "admin", 
    "population": "11021000", 
    "population_proper": "11021000"
  }, 
  {
    city: "Faisalabad", 
    "lat": "31.4180", 
    "lng": "73.0790", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "3203846", 
    "population_proper": "3203846"
  }, 
  {
    city: "Rawalpindi", 
    "lat": "33.6007", 
    "lng": "73.0679", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "2098231", 
    "population_proper": "2098231"
  }, 
  {
    city: "Gujranwala", 
    "lat": "32.1500", 
    "lng": "74.1833", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "2027001", 
    "population_proper": "2027001"
  }, 
  {
    city: "Peshawar", 
    "lat": "34.0000", 
    "lng": "71.5000", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "admin", 
    "population": "1970042", 
    "population_proper": "1970042"
  }, 
  {
    city: "Multan", 
    "lat": "30.1978", 
    "lng": "71.4711", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "1871843", 
    "population_proper": "1871843"
  }, 
  {
    city: "Saidu Sharif", 
    "lat": "34.7500", 
    "lng": "72.3572", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "1860310", 
    "population_proper": "1860310"
  }, 
  {
    city: "Hyderabad City", 
    "lat": "25.3792", 
    "lng": "68.3683", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "1732693", 
    "population_proper": "1732693"
  }, 
  {
    city: "Islamabad", 
    "lat": "33.6989", 
    "lng": "73.0369", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Islāmābād", 
    "capital": "primary", 
    "population": "1014825", 
    "population_proper": "1014825"
  }, 
  {
    city: "Quetta", 
    "lat": "30.1920", 
    "lng": "67.0070", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "admin", 
    "population": "1001205", 
    "population_proper": "1001205"
  }, 
  {
    city: "Bahawalpur", 
    "lat": "29.3956", 
    "lng": "71.6722", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "762111", 
    "population_proper": "762111"
  }, 
  {
    city: "Sargodha", 
    "lat": "32.0836", 
    "lng": "72.6711", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "659862", 
    "population_proper": "659862"
  }, 
  {
    city: "Sialkot City", 
    "lat": "32.5000", 
    "lng": "74.5333", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "655852", 
    "population_proper": "655852"
  }, 
  {
    city: "Sukkur", 
    "lat": "27.6995", 
    "lng": "68.8673", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "499900", 
    "population_proper": "499900"
  }, 
  {
    city: "Larkana", 
    "lat": "27.5600", 
    "lng": "68.2264", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "490508", 
    "population_proper": "490508"
  }, 
  {
    city: "Chiniot", 
    "lat": "31.7167", 
    "lng": "72.9833", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "477781", 
    "population_proper": "477781"
  }, 
  {
    city: "Shekhupura", 
    "lat": "31.7083", 
    "lng": "74.0000", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "473129", 
    "population_proper": "473129"
  }, 
  {
    city: "Jhang City", 
    "lat": "31.2681", 
    "lng": "72.3181", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "414131", 
    "population_proper": "414131"
  }, 
  {
    city: "Dera Ghazi Khan", 
    "lat": "30.0500", 
    "lng": "70.6333", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "399064", 
    "population_proper": "399064"
  }, 
  {
    city: "Gujrat", 
    "lat": "32.5736", 
    "lng": "74.0789", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "390533", 
    "population_proper": "390533"
  }, 
  {
    city: "Rahimyar Khan", 
    "lat": "28.4202", 
    "lng": "70.2952", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "353203", 
    "population_proper": "353203"
  }, 
  {
    city: "Kasur", 
    "lat": "31.1167", 
    "lng": "74.4500", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "314617", 
    "population_proper": "314617"
  }, 
  {
    city: "Mardan", 
    "lat": "34.1958", 
    "lng": "72.0447", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "300424", 
    "population_proper": "300424"
  }, 
  {
    city: "Mingaora", 
    "lat": "34.7717", 
    "lng": "72.3600", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "", 
    "population": "279914", 
    "population_proper": "279914"
  }, 
  {
    city: "Nawabshah", 
    "lat": "26.2442", 
    "lng": "68.4100", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "263102", 
    "population_proper": "263102"
  }, 
  {
    city: "Sahiwal", 
    "lat": "30.6706", 
    "lng": "73.1064", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "247706", 
    "population_proper": "247706"
  }, 
  {
    city: "Mirpur Khas", 
    "lat": "25.5269", 
    "lng": "69.0111", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "236961", 
    "population_proper": "236961"
  }, 
  {
    city: "Okara", 
    "lat": "30.8100", 
    "lng": "73.4597", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "232386", 
    "population_proper": "232386"
  }, 
  {
    city: "Mandi Burewala", 
    "lat": "30.1500", 
    "lng": "72.6833", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "203454", 
    "population_proper": "203454"
  }, 
  {
    city: "Jacobabad", 
    "lat": "28.2769", 
    "lng": "68.4514", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "200815", 
    "population_proper": "200815"
  }, 
  {
    city: "Saddiqabad", 
    "lat": "28.3006", 
    "lng": "70.1302", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "189876", 
    "population_proper": "189876"
  }, 
  {
    city: "Kohat", 
    "lat": "33.5869", 
    "lng": "71.4414", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "170800", 
    "population_proper": "170800"
  }, 
  {
    city: "Muridke", 
    "lat": "31.8020", 
    "lng": "74.2550", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "163268", 
    "population_proper": "163268"
  }, 
  {
    city: "Muzaffargarh", 
    "lat": "30.0703", 
    "lng": "71.1933", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "163268", 
    "population_proper": "163268"
  }, 
  {
    city: "Khanpur", 
    "lat": "28.6471", 
    "lng": "70.6620", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "160308", 
    "population_proper": "160308"
  }, 
  {
    city: "Gojra", 
    "lat": "31.1500", 
    "lng": "72.6833", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "157863", 
    "population_proper": "157863"
  }, 
  {
    city: "Mandi Bahauddin", 
    "lat": "32.5861", 
    "lng": "73.4917", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "157352", 
    "population_proper": "157352"
  }, 
  {
    city: "Abbottabad", 
    "lat": "34.1500", 
    "lng": "73.2167", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "148587", 
    "population_proper": "148587"
  }, 
  {
    city: "Turbat", 
    "lat": "26.0031", 
    "lng": "63.0544", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "147791", 
    "population_proper": "75694"
  }, 
  {
    city: "Dadu", 
    "lat": "26.7319", 
    "lng": "67.7750", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "146179", 
    "population_proper": "146179"
  }, 
  {
    city: "Bahawalnagar", 
    "lat": "29.9944", 
    "lng": "73.2536", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "141935", 
    "population_proper": "141935"
  }, 
  {
    city: "Khuzdar", 
    "lat": "27.8000", 
    "lng": "66.6167", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "", 
    "population": "141395", 
    "population_proper": "141395"
  }, 
  {
    city: "Pakpattan", 
    "lat": "30.3500", 
    "lng": "73.4000", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "139525", 
    "population_proper": "139525"
  }, 
  {
    city: "Tando Allahyar", 
    "lat": "25.4667", 
    "lng": "68.7167", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "133487", 
    "population_proper": "133487"
  }, 
  {
    city: "Ahmadpur East", 
    "lat": "29.1453", 
    "lng": "71.2617", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "128112", 
    "population_proper": "128112"
  }, 
  {
    city: "Vihari", 
    "lat": "30.0419", 
    "lng": "72.3528", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "128034", 
    "population_proper": "128034"
  }, 
  {
    city: "Jaranwala", 
    "lat": "31.3342", 
    "lng": "73.4194", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "127973", 
    "population_proper": "127973"
  }, 
  {
    city: "New Mirpur", 
    "lat": "33.1333", 
    "lng": "73.7500", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Azad Kashmir", 
    "capital": "minor", 
    "population": "124352", 
    "population_proper": "124352"
  }, 
  {
    city: "Kamalia", 
    "lat": "30.7258", 
    "lng": "72.6447", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "121401", 
    "population_proper": "121401"
  }, 
  {
    city: "Kot Addu", 
    "lat": "30.4700", 
    "lng": "70.9644", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "120479", 
    "population_proper": "120479"
  }, 
  {
    city: "Nowshera", 
    "lat": "34.0153", 
    "lng": "71.9747", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "118594", 
    "population_proper": "118594"
  }, 
  {
    city: "Swabi", 
    "lat": "34.1167", 
    "lng": "72.4667", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "115018", 
    "population_proper": "115018"
  }, 
  {
    city: "Khushab", 
    "lat": "32.2917", 
    "lng": "72.3500", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "110868", 
    "population_proper": "110868"
  }, 
  {
    city: "Dera Ismail Khan", 
    "lat": "31.8167", 
    "lng": "70.9167", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "109686", 
    "population_proper": "109686"
  }, 
  {
    city: "Chaman", 
    "lat": "30.9210", 
    "lng": "66.4597", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "", 
    "population": "107660", 
    "population_proper": "107660"
  }, 
  {
    city: "Charsadda", 
    "lat": "34.1453", 
    "lng": "71.7308", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "105414", 
    "population_proper": "105414"
  }, 
  {
    city: "Kandhkot", 
    "lat": "28.4000", 
    "lng": "69.3000", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "", 
    "population": "105011", 
    "population_proper": "105011"
  }, 
  {
    city: "Chishtian", 
    "lat": "29.7958", 
    "lng": "72.8578", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "101659", 
    "population_proper": "101659"
  }, 
  {
    city: "Hasilpur", 
    "lat": "29.6967", 
    "lng": "72.5542", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "99171", 
    "population_proper": "99171"
  }, 
  {
    city: "Attock Khurd", 
    "lat": "33.7667", 
    "lng": "72.3667", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "97374", 
    "population_proper": "97374"
  }, 
  {
    city: "Muzaffarabad", 
    "lat": "34.3700", 
    "lng": "73.4711", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Azad Kashmir", 
    "capital": "", 
    "population": "96000", 
    "population_proper": "96000"
  }, 
  {
    city: "Mianwali", 
    "lat": "32.5853", 
    "lng": "71.5436", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "95007", 
    "population_proper": "95007"
  }, 
  {
    city: "Jalalpur Jattan", 
    "lat": "32.7667", 
    "lng": "74.2167", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "90130", 
    "population_proper": "90130"
  }, 
  {
    city: "Bhakkar", 
    "lat": "31.6333", 
    "lng": "71.0667", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "88472", 
    "population_proper": "88472"
  }, 
  {
    city: "Zhob", 
    "lat": "31.3417", 
    "lng": "69.4486", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "88356", 
    "population_proper": "50537"
  }, 
  {
    city: "Dipalpur", 
    "lat": "30.6708", 
    "lng": "73.6533", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "87176", 
    "population_proper": "87176"
  }, 
  {
    city: "Kharian", 
    "lat": "32.8110", 
    "lng": "73.8650", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "85765", 
    "population_proper": "85765"
  }, 
  {
    city: "Mian Channun", 
    "lat": "30.4397", 
    "lng": "72.3544", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "82586", 
    "population_proper": "82586"
  }, 
  {
    city: "Bhalwal", 
    "lat": "32.2653", 
    "lng": "72.9028", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "82556", 
    "population_proper": "82556"
  }, 
  {
    city: "Jamshoro", 
    "lat": "25.4283", 
    "lng": "68.2822", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "80000", 
    "population_proper": "80000"
  }, 
  {
    city: "Pattoki", 
    "lat": "31.0214", 
    "lng": "73.8528", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "77210", 
    "population_proper": "77210"
  }, 
  {
    city: "Harunabad", 
    "lat": "29.6100", 
    "lng": "73.1361", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "77206", 
    "population_proper": "77206"
  }, 
  {
    city: "Kahror Pakka", 
    "lat": "29.6236", 
    "lng": "71.9167", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "76098", 
    "population_proper": "76098"
  }, 
  {
    city: "Toba Tek Singh", 
    "lat": "30.9667", 
    "lng": "72.4833", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "75943", 
    "population_proper": "75943"
  }, 
  {
    city: "Samundri", 
    "lat": "31.0639", 
    "lng": "72.9611", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "73911", 
    "population_proper": "73911"
  }, 
  {
    city: "Shakargarh", 
    "lat": "32.2628", 
    "lng": "75.1583", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "73160", 
    "population_proper": "73160"
  }, 
  {
    city: "Sambrial", 
    "lat": "32.4750", 
    "lng": "74.3522", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "71766", 
    "population_proper": "71766"
  }, 
  {
    city: "Shujaabad", 
    "lat": "29.8803", 
    "lng": "71.2950", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "70595", 
    "population_proper": "70595"
  }, 
  {
    city: "Hujra Shah Muqim", 
    "lat": "30.7408", 
    "lng": "73.8219", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "70204", 
    "population_proper": "70204"
  }, 
  {
    city: "Kabirwala", 
    "lat": "30.4068", 
    "lng": "71.8667", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "70123", 
    "population_proper": "70123"
  }, 
  {
    city: "Mansehra", 
    "lat": "34.3333", 
    "lng": "73.2000", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "69085", 
    "population_proper": "69085"
  }, 
  {
    city: "Lala Musa", 
    "lat": "32.7006", 
    "lng": "73.9558", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "67283", 
    "population_proper": "67283"
  }, 
  {
    city: "Chunian", 
    "lat": "30.9639", 
    "lng": "73.9803", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "64386", 
    "population_proper": "64386"
  }, 
  {
    city: "Nankana Sahib", 
    "lat": "31.4492", 
    "lng": "73.7124", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "63073", 
    "population_proper": "63073"
  }, 
  {
    city: "Bannu", 
    "lat": "32.9889", 
    "lng": "70.6056", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "62191", 
    "population_proper": "62191"
  }, 
  {
    city: "Pasrur", 
    "lat": "32.2681", 
    "lng": "74.6675", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "58644", 
    "population_proper": "58644"
  }, 
  {
    city: "Timargara", 
    "lat": "34.8281", 
    "lng": "71.8408", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "58050", 
    "population_proper": "58050"
  }, 
  {
    city: "Parachinar", 
    "lat": "33.8992", 
    "lng": "70.1008", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "", 
    "population": "55685", 
    "population_proper": "55685"
  }, 
  {
    city: "Chenab Nagar", 
    "lat": "31.7500", 
    "lng": "72.9167", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "53965", 
    "population_proper": "53965"
  }, 
  {
    city: "Gwadar", 
    "lat": "25.1264", 
    "lng": "62.3225", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "51901", 
    "population_proper": "23364"
  }, 
  {
    city: "Abdul Hakim", 
    "lat": "30.5522", 
    "lng": "72.1278", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "51494", 
    "population_proper": "51494"
  }, 
  {
    city: "Hassan Abdal", 
    "lat": "33.8195", 
    "lng": "72.6890", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "50044", 
    "population_proper": "50044"
  }, 
  {
    city: "Tank", 
    "lat": "32.2167", 
    "lng": "70.3833", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "44996", 
    "population_proper": "44996"
  }, 
  {
    city: "Hangu", 
    "lat": "33.5281", 
    "lng": "71.0572", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "39766", 
    "population_proper": "39766"
  }, 
  {
    city: "Risalpur Cantonment", 
    "lat": "34.0049", 
    "lng": "71.9989", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "", 
    "population": "36653", 
    "population_proper": "36653"
  }, 
  {
    city: "Karak", 
    "lat": "33.1167", 
    "lng": "71.0833", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "35844", 
    "population_proper": "35844"
  }, 
  {
    city: "Kundian", 
    "lat": "32.4522", 
    "lng": "71.4718", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "35406", 
    "population_proper": "35406"
  }, 
  {
    city: "Umarkot", 
    "lat": "25.3614", 
    "lng": "69.7361", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "35059", 
    "population_proper": "35059"
  }, 
  {
    city: "Chitral", 
    "lat": "35.8511", 
    "lng": "71.7889", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "31100", 
    "population_proper": "31100"
  }, 
  {
    city: "Dainyor", 
    "lat": "35.9206", 
    "lng": "74.3783", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Gilgit-Baltistan", 
    "capital": "", 
    "population": "25000", 
    "population_proper": "25000"
  }, 
  {
    city: "Kulachi", 
    "lat": "31.9286", 
    "lng": "70.4592", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "", 
    "population": "24738", 
    "population_proper": "24738"
  }, 
  {
    city: "Kalat", 
    "lat": "29.0258", 
    "lng": "66.5900", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "22879", 
    "population_proper": "22879"
  }, 
  {
    city: "Kotli", 
    "lat": "33.5156", 
    "lng": "73.9019", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Azad Kashmir", 
    "capital": "minor", 
    "population": "21462", 
    "population_proper": "21462"
  }, 
  {
    city: "Gilgit", 
    "lat": "35.9208", 
    "lng": "74.3144", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Gilgit-Baltistan", 
    "capital": "minor", 
    "population": "8851", 
    "population_proper": "8851"
  }, 
  {
    city: "Narowal", 
    "lat": "32.1020", 
    "lng": "74.8730", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Khairpur Mir’s", 
    "lat": "27.5295", 
    "lng": "68.7592", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Khanewal", 
    "lat": "30.3017", 
    "lng": "71.9321", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Jhelum", 
    "lat": "32.9333", 
    "lng": "73.7333", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Haripur", 
    "lat": "33.9942", 
    "lng": "72.9333", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Shikarpur", 
    "lat": "27.9556", 
    "lng": "68.6382", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Rawala Kot", 
    "lat": "33.8578", 
    "lng": "73.7604", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Azad Kashmir", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Hafizabad", 
    "lat": "32.0709", 
    "lng": "73.6880", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Lodhran", 
    "lat": "29.5383", 
    "lng": "71.6333", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Malakand", 
    "lat": "34.5656", 
    "lng": "71.9304", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Attock City", 
    "lat": "33.7667", 
    "lng": "72.3598", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Batgram", 
    "lat": "34.6796", 
    "lng": "73.0263", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Matiari", 
    "lat": "25.5971", 
    "lng": "68.4467", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Ghotki", 
    "lat": "28.0064", 
    "lng": "69.3150", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Naushahro Firoz", 
    "lat": "26.8401", 
    "lng": "68.1227", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Alpurai", 
    "lat": "34.9000", 
    "lng": "72.6556", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Bagh", 
    "lat": "33.9803", 
    "lng": "73.7747", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Azad Kashmir", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Daggar", 
    "lat": "34.5111", 
    "lng": "72.4844", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Leiah", 
    "lat": "30.9646", 
    "lng": "70.9444", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Tando Muhammad Khan", 
    "lat": "25.1239", 
    "lng": "68.5389", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Chakwal", 
    "lat": "32.9300", 
    "lng": "72.8500", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Badin", 
    "lat": "24.6558", 
    "lng": "68.8383", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Lakki", 
    "lat": "32.6072", 
    "lng": "70.9123", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Rajanpur", 
    "lat": "29.1041", 
    "lng": "70.3297", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Punjab", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Dera Allahyar", 
    "lat": "28.4167", 
    "lng": "68.1667", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Shahdad Kot", 
    "lat": "27.8473", 
    "lng": "67.9068", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Pishin", 
    "lat": "30.5833", 
    "lng": "67.0000", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Sanghar", 
    "lat": "26.0464", 
    "lng": "68.9481", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Upper Dir", 
    "lat": "35.2074", 
    "lng": "71.8768", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Thatta", 
    "lat": "24.7461", 
    "lng": "67.9243", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Sindh", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Dera Murad Jamali", 
    "lat": "28.5466", 
    "lng": "68.2231", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Kohlu", 
    "lat": "29.8965", 
    "lng": "69.2532", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Mastung", 
    "lat": "29.7997", 
    "lng": "66.8455", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Dasu", 
    "lat": "35.2917", 
    "lng": "73.2906", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Khyber Pakhtunkhwa", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Athmuqam", 
    "lat": "34.5717", 
    "lng": "73.8972", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Azad Kashmir", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Loralai", 
    "lat": "30.3705", 
    "lng": "68.5979", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Barkhan", 
    "lat": "29.8977", 
    "lng": "69.5256", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Musa Khel Bazar", 
    "lat": "30.8594", 
    "lng": "69.8221", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Ziarat", 
    "lat": "30.3814", 
    "lng": "67.7258", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Gandava", 
    "lat": "28.6132", 
    "lng": "67.4856", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Sibi", 
    "lat": "29.5430", 
    "lng": "67.8773", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Dera Bugti", 
    "lat": "29.0362", 
    "lng": "69.1585", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Eidgah", 
    "lat": "35.3471", 
    "lng": "74.8563", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Gilgit-Baltistan", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Uthal", 
    "lat": "25.8072", 
    "lng": "66.6228", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Khuzdar", 
    "lat": "27.7384", 
    "lng": "66.6434", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Chilas", 
    "lat": "35.4206", 
    "lng": "74.0967", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Gilgit-Baltistan", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Panjgur", 
    "lat": "26.9644", 
    "lng": "64.0903", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Gakuch", 
    "lat": "36.1736", 
    "lng": "73.7667", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Gilgit-Baltistan", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Qila Saifullah", 
    "lat": "30.7008", 
    "lng": "68.3598", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Kharan", 
    "lat": "28.5833", 
    "lng": "65.4167", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Aliabad", 
    "lat": "36.3078", 
    "lng": "74.6178", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Gilgit-Baltistan", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Awaran", 
    "lat": "26.4568", 
    "lng": "65.2314", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  }, 
  {
    city: "Dalbandin", 
    "lat": "28.8885", 
    "lng": "64.4062", 
    "country": "Pakistan", 
    "iso2": "PK", 
    "admin_name": "Balochistān", 
    "capital": "minor", 
    "population": "", 
    "population_proper": ""
  },
  {
    city: "Gwadar",
    "lat": "25.1212",
    "lng": "62.3254",
    "country": "Pakistan",
    "iso2": "PK",
    "admin_name": "Balochistān",
    "capital": "minor",
    "population": "",
    "population_proper": ""
  },
  {
    city: "Kohlu",
    "lat": "29.0000",
    "lng": "69.0000",
    "country": "Pakistan",
  },
  {
    city:"Other",
    "lat": "30.0000",
    "lng": "70.0000",
    "country": "Pakistan",
  }
]

export default pkCities;