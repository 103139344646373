import React, { useEffect, useState, useRef } from 'react';
import "./style_home.css";
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Home = () => {
    const [years, setYears] = useState(0);
    const [happyCustomers, setHappyCustomers] = useState(0);
    const [vehicles, setVehicles] = useState(0);
    const [staff, setStaff] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [featuredVehicles, setFeaturedVehicles] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const vehiclesRef = useRef(null); // Add this line
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const achievementRef = useRef(null);
    const serviceRef = useRef(null);
    const consultancyRef = useRef(null);
    const customersRef = useRef(null); // Ref for the new section
    const contactRef = useRef(null); // Ref for the new section

    const [animated, setAnimated] = useState({
        achievements: [false, false, false, false],
        service: false,
        consultancy: false,
        customers: false, // New key for customers section
        contact: false, // New key for contact section
        vehicles: false,
    });

    useEffect(() => {

        const fetchFeaturedVehicles = async () => {
            try {
                const response = await fetch('https://technationals-cors-anywhere-c044beaa3884.herokuapp.com/https://api.aboutcars.pk/api/cars/all-sell-cars');
                const data = await response.json();
                if (data.Status === "Success") {
                    // Sort vehicles by date in descending order and take the latest 3
                    const sortedVehicles = data.data
                        .filter(vehicle => vehicle.carImages.length > 0) // Filter vehicles with images
                        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date (newest first)
                        .slice(0, 3); // Take only the latest 3 vehicles

                    setFeaturedVehicles(sortedVehicles);
                }
            } catch (error) {
                console.error('Error fetching featured vehicles:', error);
            }
        };


        fetchFeaturedVehicles();

        const handleScroll = () => {
            // Check if achievement section is in the viewport
            if (achievementRef.current) {
                const rect = achievementRef.current.getBoundingClientRect();
                if (rect.top < window.innerHeight && rect.bottom >= 0 && !hasAnimated) {
                    setHasAnimated(true);
                    startCounters(); // Start counters for achievements
                }
                // Check if each individual achievement box is in the viewport
                const boxes = achievementRef.current.querySelectorAll('.achievement-box');
                boxes.forEach((box, index) => {
                    const boxRect = box.getBoundingClientRect();
                    if (boxRect.top < window.innerHeight && boxRect.bottom >= 0 && !animated.achievements[index]) {
                        setAnimated(prev => {
                            const newAnimated = { ...prev };
                            newAnimated.achievements[index] = true;
                            return newAnimated;
                        });
                    }
                });
            }

            // Check if other sections are in the viewport
            const sections = [
                { ref: serviceRef, key: 'service' },
                { ref: consultancyRef, key: 'consultancy' },
                { ref: customersRef, key: 'customers' },
                { ref: contactRef, key: 'contact' },
                { ref: vehiclesRef, key: 'vehicles' },
            ];
            sections.forEach(({ ref, key }) => {
                if (ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    if (rect.top < window.innerHeight && rect.bottom >= 0 && !animated[key]) {
                        setAnimated(prev => ({
                            ...prev,
                            [key]: true,
                        }));
                    }
                }
            });
        };

        const startCounters = () => {
            const yearsTarget = new Date().getFullYear() - 2015;
            let yearsCount = 0;
            const yearsInterval = setInterval(() => {
                if (yearsCount < yearsTarget) {
                    yearsCount++;
                    setYears(yearsCount);
                } else {
                    clearInterval(yearsInterval);
                }
            }, 30);

            const happyCustomersTarget = 5000;
            let happyCustomersCount = 4800;
            const happyCustomersInterval = setInterval(() => {
                if (happyCustomersCount < happyCustomersTarget) {
                    happyCustomersCount++;
                    setHappyCustomers(happyCustomersCount);
                } else {
                    clearInterval(happyCustomersInterval);
                }
            }, 30);

            const vehiclesTarget = Math.floor(Math.random() * (50 - 25 + 1)) + 25;
            let vehiclesCount = 0;
            const vehiclesInterval = setInterval(() => {
                if (vehiclesCount < vehiclesTarget) {
                    vehiclesCount++;
                    setVehicles(vehiclesCount);
                } else {
                    clearInterval(vehiclesInterval);
                }
            }, 30);

            const staffTarget = 12;
            let staffCount = 0;
            const staffInterval = setInterval(() => {
                if (staffCount < staffTarget) {
                    staffCount++;
                    setStaff(staffCount);
                } else {
                    clearInterval(staffInterval);
                }
            }, 30);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasAnimated, animated]);

    return (
        <>
            <div className="container-fluid m-0 p-0" style={{ top: 0, left: 0, right: 0, zIndex: 1 }}>
                <div style={{ backgroundColor: "#20111E", color: "white" }} className="py-3">
                    <div className="row align-items-center m-0">
                        <div className="col text-left mb-2">
                            <div className="running-text">
                                <p className="mb-0 animate__animated animate__slideInRight">Buy, import, and sell your car through us. It's all about cars!</p>
                            </div>
                        </div>
                        <div className="col text-right d-flex justify-content-end">
                            <div className="mx-2">
                                <i className="fas fa-clock"></i>
                                <span className="ml-1">Mon – Sun (10 AM – 7 PM)</span>
                            </div>
                            <div className="mx-2">
                                <i className="fas fa-envelope" onClick={() => window.location.href = 'mailto:info@aboutcars.pk'} style={{ cursor: 'pointer' }}></i>
                                <span className="ml-1" onClick={() => window.location.href = 'mailto:info@aboutcars.pk'} style={{ cursor: 'pointer' }}>info@aboutcars.pk</span>
                            </div>
                            <div className="mx-2">
                                <i className="fas fa-phone" onClick={() => window.location.href = 'tel:03226882277'} style={{ cursor: 'pointer' }}></i>
                                <span className="ml-1" onClick={() => window.location.href = 'tel:03226882277'} style={{ cursor: 'pointer' }}>0322 6882277</span>
                            </div>
                        </div>
                    </div>





                    <nav className="navbar navbar-expand-lg navbar-light m-0">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">
                                <img className="logo" src="assets/images/logo.png" style={{ width: "50%" }} alt="" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarCollapse">
                                <ul className="navbar-nav mr-auto mb-2 mb-md-0">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#service">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#Consultancy">Consultancy</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#about">About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#contact">Contact</a>
                                    </li>
                                </ul>
                                <div className="d-flex nav-btns">
                                    <Link className="nav-btns-1 mr-3" to="/sell">Sell Now</Link>
                                    <Link className="ms-2 nav-btns-2" to="/buy">Buy Now</Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <section className="container-fluid head-sec p-0">
                <div className="row m-0" id="backg">
                    <div className="col-md-6 col-lg-7 col-sm-12 hero-text">
                        <h1 className="header-text mb-3 ps-2 animate__animated animate__fadeInUp animate__delay-1s">The best car <br /> dealership operating<br />in the twin cities</h1>
                        <p className="para mb-4 ps-2 animate__animated animate__slideInLeft animate__delay-2s">At About Cars we offer customer satisfaction whether you <br /> are here to buy your new dream car or sell an old one
                        </p>
                        <a className="ms-2 nav-btns-2" href="#service">Explore Now</a>
                    </div>
                    <div className="col-lg-5 col-sm-12 image p-0">
                        <div className='car_hero_image_div' style={{ height: '100%' }}>
                            <img
                                src="assets/images/457.png"
                                className="img-res img-res-car"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain", // Change to 'contain' to keep the aspect ratio
                                }}
                                alt="Car"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid" id="service" ref={serviceRef}>
                <h2 className={`text-center mb-5 border-t animate__animated ${animated.service ? 'animate__fadeInDown' : ''}`}>Our Services</h2>
                <div className="row justify-content-around">
                    <div className="col-md-4 col-sm-6 mb-4">
                        <div className={`service-box animate__animated ${animated.service ? 'animate__fadeInLeft' : ''} animate__delay-1s`}>
                            <img src="assets/images/car.png" className="img-res mb-4" width="45%" alt="Sell a car" />
                            <h3>Sell a Car</h3>
                            <p>We help you to sell your car by showcasing it at one of our renowned showrooms or by simply providing us with pictures and details of your car to find the best possible buyer for it.</p>
                            <Link className="btn nav-btns-2" style={{ marginTop: '23px' }} to="/sell">Sell Now</Link>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                        <div className={`service-box animate__animated ${animated.service ? 'animate__fadeInRight' : ''} animate__delay-1s`}>
                            <img src="assets/images/key.png" className="img-res mb-4" width="28%" alt="Buy a car" />
                            <h3>Buy a Car</h3>
                            <p>We help you to buy your car by showcasing it at one of our renowned showrooms or by simply providing us with pictures and details of your car to find the best possible seller for it.</p>
                            <Link className="btn nav-btns-2" style={{ marginTop: '23px' }} to="/buy">Buy Now</Link>

                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid my-5" ref={vehiclesRef}>
                <h2 className="text-center mb-4 border-text">Vehicles in Stock</h2>
                <style>
                    {`
            .carousel-inner {
               height: auto !important; 
  margin-top: 0px !important; 
   border: 1px solid #ebcdcd;
            }
        `}
                </style>
                <div className="row justify-content-center">
                    {featuredVehicles.slice(0, 3).map((vehicle) => {
                        // Check if the vehicle has images
                        if (!vehicle.carImages || vehicle.carImages.length === 0) {
                            return null; // Skip this vehicle if no images are available
                        }

                        return (
                            <div className="col-md-4 col-sm-6 mb-4" key={vehicle._id}>
                                <div className="card vehicle-card">
                                    <Carousel
                                        nextLabel=""
                                        prevLabel=""
                                        controls={true}
                                        indicators={false} // Hide indicators
                                    >
                                        {vehicle.carImages.map((image, index) => (
                                            <Carousel.Item key={index}>
                                                <img
                                                    src={`https://api.aboutcars.pk/api/${image}`}
                                                    className="d-block w-100"
                                                    alt={`${vehicle.make.model} - ${index + 1}`}
                                                    style={{
                                                        objectFit: 'contain',
                                                        height: '300px', // Fixed height
                                                        width: '100%',
                                                    }}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    <div className="card-body">
                                        <h5 className="card-title">{`${vehicle.make.year} ${vehicle.make.make} ${vehicle.make.model}`}</h5>
                                        <p className="card-text2">Color: {vehicle.exteriorColor}</p>
                                        <p className="card-text2">Mileage: {vehicle.milage} km</p>
                                        <p className="card-text2">Transmission: {vehicle.transmission}</p>
                                        <Link to={`/vehicle/${vehicle._id}`} className="btn btn-danger">View Details</Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>


            <section id="Consultancy" ref={consultancyRef} style={{ backgroundColor: "#fff", padding: "50px 0" }}>
                <div className='container'>
                    <div className="row align-items-center">
                        <div className="col-md-6 animate__animated ${animated.consultancy ? 'animate__fadeInLeft' : ''}">
                            <img src="https://aboutcars.pk/assets/images/445.png" className="img-fluid" alt="Consultancy" />
                        </div>
                        <div className="col-md-6 text-center">
                            <h2 className={`mb-4 animate__animated ${animated.consultancy ? 'animate__fadeInRight' : ''}`}>Consultancy Services</h2>
                            <p className={`mb-5 animate__animated ${animated.consultancy ? 'animate__fadeInRight' : ''}`}>
                                We provide expert consultancy services to help you make the best choices in buying or selling your vehicle.
                            </p>
                            <a href="tel:03226882277" className="btn nav-btns-1" style={{ cursor: 'pointer' }}>
                                Call Now
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section id="about" className="container mt-5" ref={achievementRef} style={{ marginTop: "150px" }}>
                <h2 className="text-center animate__animated animate__fadeInDown">What We Have Achieved</h2>
                <div className="row text-center mt-4">
                    <div className="col-md-3 mb-4 achievement-box">
                        <div className={`card animate__animated ${animated[0] ? 'animate__fadeInUp' : ''} animate__delay-1s`}>
                            <div className="card-body">
                                <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{years}+</h3>
                                <p style={{ color: "red" }}>Years of Excellence</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4 achievement-box">
                        <div className={`card animate__animated ${animated[1] ? 'animate__fadeInUp' : ''} animate__delay-2s`}>
                            <div className="card-body">
                                <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{happyCustomers}+</h3>
                                <p style={{ color: "red" }}>Happy Customers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4 achievement-box">
                        <div className={`card animate__animated ${animated[2] ? 'animate__fadeInUp' : ''} animate__delay-3s`}>
                            <div className="card-body">
                                <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{vehicles}</h3>
                                <p style={{ color: "red" }}>Vehicles In Stock</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4 achievement-box">
                        <div className={`card animate__animated ${animated[3] ? 'animate__fadeInUp' : ''} animate__delay-4s`}>
                            <div className="card-body">
                                <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{staff}</h3>
                                <p style={{ color: "red" }}>Qualified Staff</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact" ref={contactRef} style={{ backgroundColor: "rgb(255 255 255)", padding: "50px 0" }}>
                <div className="container text-center">
                    <h2 className={`mb-4 animate__animated ${animated.contact ? 'animate__fadeInDown' : ''}`}>Contact Us</h2>
                    <p className={`mb-5 animate__animated ${animated.contact ? 'animate__fadeInDown' : ''}`}>Get in touch with us for any inquiries or assistance.</p>
                    <a
                        className="btn nav-btns-1"
                        href="tel:03226882277"
                        style={{ borderRadius: "25px", padding: "10px 20px", display: "inline-block" }}>
                        Call Now
                    </a>
                </div>
            </section>

            <section ref={customersRef} className="container mt-5" style={{ marginTop: "80px", paddingBottom: "50px" }}>
                <div className="row align-items-center">
                    <div className="col-md-6 animate__animated animate__fadeInLeft" style={{ opacity: animated.customers ? 1 : 0 }}>
                        <h2 className="happy-customers-title">Our Happy Customers</h2>
                        <p>We strive to provide the best service and support to our customers. Their satisfaction is our top priority, and we take pride in their success stories.</p>
                    </div>
                    <div className="col-md-6 animate__animated animate__fadeInRight" style={{ opacity: animated.customers ? 1 : 0 }}>
                        <img
                            src="assets/images/happycs.jpg"
                            alt="Group of happy customers celebrating their experience with our service."
                            className="img-fluid"
                            style={{ maxWidth: "100%" }}
                        />
                    </div>
                </div>
            </section>


            {/* New Contact Section */}
            <section ref={contactRef} style={{ background: "linear-gradient(to right, #f9fafc, #e0e7ff)" }} id="contact">
                <div className="container wrapper-call">
                    <div className="row d-flex align-items-center pb-3 dis-mobile" style={{ height: "100%" }}>
                        <h3 className="col-9 pt-2 animate__animated animate__fadeInUp" style={{ color: "#333", textAlign: "left", opacity: animated.contact ? 1 : 0 }}>
                            <span className="pxs" style={{ fontWeight: "bold", fontSize: "24px" }}>Want to import a car?</span>
                            <span style={{ fontWeight: 400 }}></span>
                            <span style={{ fontWeight: 400, fontSize: "16px", padding: "10px 0", display: "block" }} className="pxs-2">
                                Our Agents Help You on Every Step
                            </span>
                        </h3>
                        <div className="col-3 mob-btn text-end">
                            <a
                                className="btn btn-primary ms-2 nav-btns-2 nav-btns-3 animate__animated animate__fadeInUp"
                                href="tel:03226882277"
                                style={{ borderRadius: "25px", padding: "10px 20px", opacity: animated.contact ? 1 : 0 }}>
                                <i className="fas fa-phone-alt"></i> Call Now
                            </a>

                        </div>
                    </div>
                </div>
            </section>


            <div className="container-fluid footer">
                <footer className="row" id="footer">
                    <div className="col-md-3 col-sm-12 mb-3">
                        <a href="/" className="d-flex align-items-center justify-content-center mb-3 link-dark text-decoration-none">
                            <img src="assets/images/logo-white.png" className="me-2" width="80%" alt='Gray' />
                        </a>
                    </div>

                    <div className="col-md-6 col-lg-6 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Address</a></li>
                            <li className="nav-item mb-2"><a href="https://www.google.com/search?client=opera&q=G-81%2C+Food+Street%2C+Cricket+Stadium+Road%2C+Rawalpindi&sourceid=opera&ie=UTF-8&oe=UTF-8" className="nav-link p-0 text-muted">G-81, Food Street, Cricket Stadium Road,
                                <br />Rawalpindi</a></li>
                        </ul>
                    </div>

                    <div className="col-md-3 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Contact</a></li>
                            <li className="nav-item mb-2 d-flex justify-content-center align-items-center">
                                <a href="/" className="nav-link p-0 pr-3 text-muted">
                                    <i className="fa fa-phone "></i></a>0322-6682277
                            </li>
                            <li className="nav-item mb-2">
                                <a href="https://www.gmail.com" className="nav-link p-0 text-muted">
                                    <i className="fa fa-envelope pr-2"></i>info@aboutcars.pk
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer>

                <div className="row" style={{ borderTop: "1px solid #f3f4f5" }}>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
                        <button className="btn btn-link text-bottom" onClick={handleShow}>
                            Privacy Policy - Terms of use
                        </button>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
                        <p className="text-bottom-1">About Cars © {new Date().getFullYear()}</p>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center">
                        <div className="d-flex flex-column flex-sm-row justify-content-between mb-4">
                            <div className="d-flex flex-column flex-sm-row justify-content-between"></div>
                            <div>
                                <ul className="list-unstyled d-flex share-icon">
                                    <li className="ms-3"><a className="link-dark" href="https://www.facebook.com/aboutcars.pk/"><i className="fa fa-facebook"></i></a></li>
                                    <li className="ms-3"><a className="link-dark" href="https://www.google.com/search?q=aboutcars+pk&rlz=1C1CHBD_enPK1030PK1030&oq=aboutcars+pk&aqs=chrome..69i57j35i39j69i60l2.7399j0j4&sourceid=chrome&ie=UTF-8"><i className="fa fa-google"></i></a></li>
                                    <li className="ms-3"><a className="link-dark" href="https://mobile.twitter.com/AboutCarsPk"><i className="fa fa-twitter"></i></a></li>
                                    <li className="ms-3"><a className="link-dark" href="https://www.instagram.com/aboutcars.pk/"><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Privacy Policy Modal */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="privacyPolicyModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="privacyPolicyModalLabel">Privacy Policy</h5>
                            <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Your privacy is important to us. It is About Cars' policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://aboutcars.pk" target="_blank" rel="noopener noreferrer">aboutcars.pk</a>, and other sites we own and operate.</p>

                            <h5 className="font-weight-bold">Information We Collect</h5>
                            <p>We may ask for personal information which may include your name, email address, and phone number.</p>

                            <h5 className="font-weight-bold">How We Use Your Information</h5>
                            <p>We may use the information we collect from you in the following ways:</p>
                            <ul className="list-unstyled">
                                <li>• To personalize your experience</li>
                                <li>• To improve our website</li>
                                <li>• To send periodic emails regarding your order or other products and services</li>
                            </ul>

                            <h5 className="font-weight-bold">Data Security</h5>
                            <p>We take appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our site.</p>

                            <h5 className="font-weight-bold">Changes to This Privacy Policy</h5>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                            <h5 className="font-weight-bold">Contact Us</h5>
                            <p>If you have any questions about this Privacy Policy, please contact us:</p>
                            <p>Email: <a href="mailto:support@aboutcars.pk">support@aboutcars.pk</a></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </>

    );
}

export default Home;