import "../dashboard_style.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";


const SalesData = (props) => {
  // const [carData, setCarData] = React.useState([]);

  const { filter, searchSellData } = props;
  const [saveCarIcon, setSaveCarIcon] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    _id: 123123123,
    carSaved: [
      "6364adb77d1f62691241d592",
      "63654834c7ee0e7af87255b9",
      "63689b56be3fdbdc7c813ec8",
    ],
  });
  //    let carInfo;
  function getDays(day) {
    const date1 = new Date(day);
    const date2 = new Date();
    const diffTime = Math.round(Math.abs(date2 - date1) / (1000 * 60));
    const diffTime1 = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime1 / (1000 * 60 * 60 * 24));
    if (diffTime < 60) {
      return Math.floor(diffTime) + " minutes ago";
    } else if (diffTime > 60 && diffTime < 1440) {
      return Math.floor(diffTime / 60) + " hours ago";
    } else if (diffDays > 30) return Math.floor(diffDays / 30) + " months ago";
    return diffDays + " days ago";
  }

  const [userRole, setUserRole] = useState();
  useEffect(() => {
    let itemdata = localStorage.getItem("user");
    let item = JSON.parse(itemdata);
    if (item) {
      setUserRole(item.data.role);
      // setUserEmail(item.data.email);
      // setUserName(item.data.fullName);
    }
  }, []);

  // car Save query
  const handleSave = async (id) => {
    // const tokenId = localStorage.getItem('adminToken');
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}cars/carsave?carid=` +
          id +
          "&id=6373805ad71bfe510854645f"
      );
      // console.log(res);
      setSaveCarIcon(true);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const SaveSubmit = (id, event) => {
    event.stopPropagation();
    handleSave(id).then((data) => {
      console.log(data);
    });
  };

  const [dummy, setDummy] = useState(searchSellData);
  useEffect(() => {
    if (dummy !== searchSellData) {
      setDummy(searchSellData);
    }
  }, []);
  useEffect(() => {
    let len = -1;
    let len2 = -1;
    let len3 = -1;
    let len4 = -1;
    if (Object.keys(filter["city"]).length > 0)
      len = Object.keys(filter["city"]).findIndex(
        (key) => filter["city"][key] === true
      );
    if (Object.keys(filter["make"]).length > 0)
      len2 = Object.keys(filter["make"]).findIndex(
        (key) => filter["make"][key] === true
      );
    if (Object.keys(filter["color"]).length > 0)
      len3 = Object.keys(filter["color"]).findIndex(
        (key) => filter["color"][key] === true
      );
    if (Object.keys(filter["year"]).length > 0)
      len4 = Object.keys(filter["year"]).findIndex(
        (key) => filter["year"][key] === true
      );
    if (len >= 0 || len2 >= 0 || len3 >= 0 || len4 >= 0)
      setDummy(
        searchSellData.filter((car) => {
          return (
            (filter["city"][car.registerCity] === true || len === -1) &&
            (filter["make"][car.make.make] === true || len2 === -1) &&
            (filter["year"][car.make.year] === true || len4 === -1) &&
            (filter["color"][car.exteriorColor] === true || len3 === -1)
          );
        })
      );
    else setDummy(searchSellData);
  }, [searchSellData, filter]);
  const carInfo = dummy
  .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
  .map((car) => {
    return car.active ? (
      <div className="d-flex align-items-center justify-content-center" key={car._id}>
        <Link to={"/saledetail/" + car._id}>
          <div className="sales_data_wrapper col-12 p-0">
            <div className="sales_data d-flex justify-content-between">
              <div className="sales_data_image">
                <img src="assets/dashboard_images/alto.png" alt="suz" />
              </div>
              <div className="sales_data_details">
                <h2 className="text-left d-inline">
                  {car.make.make} {car.make.model} {car.make.year}{" "}
                </h2>
                -<span> {car.make.version}</span>
                <div className="d-flex ">
                  <p className="text-left pr-2 sales_data_city">
                    {car.registerCity}
                  </p>
                  <p className="text-left">{new Date(car.date).toLocaleDateString()}</p>
                </div>
              </div>
              <div className="sales_data_second_content">
                <p
                  className="text-left text-danger text-uppercase "
                  style={{ fontWeight: "600", letterSpacing: "0.12px" }}
                >
                  {car.priority.split(" ").slice(0, 2).join(" ")}
                </p>
              </div>
              <div className="sales_data_second_content sales_data_km ml-3">
                <img
                  src="assets/dashboard_images/km.png"
                  className="mr-2 search_logo"
                  alt="km"
                />
                <p className="text-left">{car.milage} km</p>
              </div>
              <div className="sales_data_second_content " style={{ width: "110px" }}>
                <img
                  src="assets/dashboard_images/auto.png"
                  className="mr-2 search_logo"
                  alt="transmission"
                />
                <p className="text-left">{car.transmission}</p>
              </div>
              <div
                className="sales_data_second_content sales_data_color 
                  d-flex justify-content-center align-items-center"
                style={{
                  width: "100px",
                  height: "40px",
                  boxShadow: "0px 5px 10px #ded1d1",
                }}
              >
                <span
                  style={{
                    background: car.exteriorColor,
                  }}
                ></span>
                <p className="text-left">{car.exteriorColor}</p>
              </div>
            </div>
          </div>
        </Link>
        {userRole === "user" ? null : (
          <div
            className="sales_data_second_content "
            style={{ width: "30px", margin: "0px", zIndex: "1" }}
          >
            <button
              title="Save"
              onClick={(e) => SaveSubmit(car._id, e)}
              onDoubleClick={() => setSaveCarIcon(false)}
            >
              <img
                src={
                  employeeData.carSaved.indexOf(car._id) > -1
                    ? "assets/dashboard_images/Path 19264.png"
                    : "assets/dashboard_images/Path 19255.svg"
                }
                className="mr-2 search_logo"
                alt="km"
              />
            </button>
          </div>
        )}
      </div>
    ) : null;
  });

  return (
    <div>
      {dummy.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center mt-5"
          style={{ height: "10vh", width: "60vw" }}
        >
          <CircularProgress />
        </div>
      ) : (
        carInfo
      )}
    </div>
  );
};

export default SalesData;
