import React, { useContext } from 'react'
import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Successfull from './components/sell/Succesfull';
import SavedRequest from './components/dashboard/dashboard_other/SavedRequest';
import EditAccount from './components/Admin/EditAccount';
import UsersArea from './components/Admin/UsersArea';
import ClosedRequest from './components/dashboard/dashboard_other/ClosedRequest';
import SalesDetails from './components/dashboard/salesdashboard/SalesDetails';
import BuyDetail from './components/dashboard/buydashboard/BuyDetail';
import Login from './components/Admin/Login';
import ErrorPage from './pages/ErrorPage';
import AdminRegister from './components/Admin/AdminRegister';
import UserLogin from './components/Users/UserLogin';
import SellCar from './components/sell/SellCar';
import LoadingComp from './components/Other/LoadingComp';
import { useGlobalContext } from './context/DataContext';
import SellSubmit from './components/sell/SellSubmit';
import ProgressAni from './components/sell/ProgressAni';
import BuyCar from './components/buy/BuyCar';
import Home from './components/Home';
import BuySubmit from './components/buy/BuySubmit';


// const Home = React.lazy(() => import('./components/Home'));
// const SellCar = React.lazy(() => import('./components/sell/SellCar'));
const AdminDashboard = React.lazy(() => import('./components/Admin/AdminDashboard'));
// const BuyCar = React.lazy(() => import('./components/buy/BuyCar')); 
const AboutCarsDashboard = React.lazy(() => import('./components/dashboard/AboutCarsDashboard'));
// const BuySubmit = React.lazy(() => import('./components/buy/BuySubmit'));
const BottomToTopIcon = React.lazy(() => import('./components/Other/BottomToTopIcon'));


function App(props) {

  const [loading, setLoading] = React.useState(true);

   const {openWait} = useGlobalContext();


  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      setUser(user.data.role);
    }
  }, [setUser]);



  return (
    <div >
      {
        loading ? <div className="loader-container">
          <div className="loading"><h1>About Cars</h1></div>
        </div> : <> 
        <Suspense fallback={<div className="loader-container">
          <div className="loading"><h1>About Cars</h1></div>
        </div>}>
        <LoadingComp open={openWait} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sell" element={<SellCar />} />
            <Route path="/buy" element={<BuyCar />} />
            <Route path="/sellsubmit" element={<SellSubmit />} />
            <Route path="/buysubmit" element={<BuySubmit />} />
            <Route path="/successfull" element={<Successfull />} />
           <Route path="/carsdashboard" element={user === 'user' || 1 ?<AboutCarsDashboard />:<ErrorPage />} />
            <Route path="/editaccount/:id" element={user === 'user' || 1 ? <EditAccount />:<ErrorPage />} />
            <Route path="/users" element={user === 'user' || 1 ?<UsersArea />:<ErrorPage />} />
            <Route path="/saledetail/:id" element={user === 'user' || 1 ?<SalesDetails />:<ErrorPage />} />
            <Route path="/buydetail/:id" element={user === 'user' || 1 ? <BuyDetail /> : <ErrorPage />} />
            
            <Route path="/adminlogin" element={<Login />} />
            <Route path="/adminregister" element={<AdminRegister />} />
            <Route path="/userlogin" element={<UserLogin />} />
            <Route path="/admindashboard" element={<AdminDashboard />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/loading" element={<LoadingComp />} />
            <Route path="/progress" element={ <ProgressAni />} />

          </Routes>
        </Suspense>
          <BottomToTopIcon />
        </>
      }
    </div>
  );
}

export default App;
