import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';


const AdminRegister = () => {

    const navigate = useNavigate();
    const [register, setRegister] = React.useState({
        email: "",
        password: "",
        name: "",
        contactno: "",
    });

    const handleAdminRegister = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setRegister({ ...register, [name]: value });
    }

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();

        const { email, password, name, contactno } = register;

        try {
            var data = JSON.stringify({
                "email": email,
                "password": password,
                "name": name,
                "contactNo": contactno
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}admin/register`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const res = await axios(config);
            if (res.data.status === "success") {
                alert("Admin Register Successfully");
                navigate('/adminlogin');
            }

            // console.log(res.data);
        } catch (error) {
            alert("Admin Registeration Failed");
            console.log(error);
        }


    }


    return (
        <div>
            <div className="container">
                <div className="registeration_div"
                    style={{ width: "30%", margin: "auto", marginTop: "10%", marginBottom: "10%" }}>
                    <h1>Admin Register</h1>
                    <div className="row">
                        <div className="col-md-12">
                            <form method='POST'>
                                <div className="form-control d-flex flex-column"
                                    style={{ width: "100%", height: "80px" }}>
                                    <label htmlFor="email" className='my-2'>Email</label>
                                    <input type="email" name="email" id="email" onChange={handleAdminRegister}
                                        style={{ width: "100%", height: "30px", border: "1px solid grey", outline: "none" }}
                                        value={register.email}
                                    />
                                </div>
                                <div className="form-control d-flex flex-column my-2"
                                    style={{ width: "100%", height: "80px" }}>
                                    <label htmlFor="Password" className='my-2'>Password</label>
                                    <input type="password" name="password" id="password"
                                        style={{ width: "100%", height: "30px", border: "1px solid grey", outline: "none" }}
                                        onChange={handleAdminRegister}
                                        value={register.password}
                                    />
                                </div>
                                <div className="form-control d-flex flex-column my-2"
                                    style={{ width: "100%", height: "80px" }}>
                                    <label htmlFor="Name" className='my-2'>Name</label>
                                    <input type="text" name="name" id="name"
                                        style={{ width: "100%", height: "30px", border: "1px solid grey", outline: "none" }}
                                        onChange={handleAdminRegister}
                                        value={register.name}
                                    />
                                </div>
                                <div className="form-control d-flex flex-column my-2"
                                    style={{ width: "100%", height: "80px" }}>
                                    <label htmlFor="contactno" className='my-2'>Contact No</label>
                                    <input type="contactno" name="contactno" id="contactno"
                                        style={{ width: "100%", height: "30px", border: "1px solid grey", outline: "none" }}
                                        onChange={handleAdminRegister}
                                        value={register.contactno}
                                    />
                                </div>
                                <button className='btn my-3' type='submit'
                                    style={{ width: "100%", height: "40px", backgroundColor: "#ed1c24", color: "white", border: "none", outline: "none" }}
                                    onClick={handleRegisterSubmit}
                                >
                                    Get Registered
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminRegister