import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const BuyDetail = () => {

    const { id } = useParams();
    const [carData, setCarData] = React.useState(null);
    //    let carInfo;

    // console.log(id);
    const getData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}cars/single-buy?id=`+id);
        // console.log(res.data);
        return res.data.data;
    }
    useEffect(() => {
        // console.log(id);
        getData().then((data) => {
            // console.log(data);
            setCarData(data);
             console.log(carData);
        });
    }, []);

    const handleCloseCar =() => {
        carClose(id).then((data) => {
            console.log(data);
        })
    }

    const carClose = async(id) => {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}cars/update-car-buy?id=`+id);
        // console.log(res.data);
        return res.data.data;
    }


  return ( <div>
          <section className="sale_details_wrapper mt-3 pt-3">
              <div className="row" style={{ margin: "10px 120px" }}>
                  <div className="col-lg-8 sale_details_data pb-5" >
                      <div className="sale_details_image position-relative" >
                          {/* Start of slideshow container  */}
                          <div className="row">
                              <div className="col-lg-2 sale_details_above_div" style={{ justifyContent: "left" }}>
                                  <img src="assets/dashboard_images/alto.png" alt="suz" />
                              </div>
                              <div className="col-lg-8 sale_details_above_div">
                                  <div className="buy_data_details " style={{width:"300px !important"}}>
                                      <h2 className="text-left d-inline" style={{ fontWeight: 600, fontSize: "22px" }}>
                                          {carData? carData.make.make:null} {carData? carData.make.model:null}  {carData? carData.from:null} -{carData? carData.to:null} <span> {carData? carData.make.version:null}</span></h2>
                                      <div className="d-flex">
                                          <p className="text-left pr-2 sales_data_city pr-3" style={{ fontWeight: 600,color:"#ed241c" }}>
                                          <span>{carData ? carData.minBudget : ""}</span>-<span>{carData ? carData.maxBudget : ""} PKR</span>
                                          </p>
                                          <p className="text-left" style={{ color:"#00000080"}}>5 mins</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-2 sale_details_above_div  flex-column " >
                                  <button>
                                      <div className='buy_details_save_button '>
                                          <img src='assets/dashboard_images/Path 19255.svg' alt="..." />
                                      </div>
                                  </button>
                              </div>
                          </div>


                          <div className="row pt-3">
                              <div className="col-lg-3 buy_details_div flex-column" style={{ alignItems: "normal" }}>
                                  <h3>Transmission</h3>
                              <p className="text-left pt-2" style={{ fontWeight: 500, fontSize: "18px" }}>{carData ? carData.transmission : ""}</p>
                              </div>
                              <div className="col-lg-3 buy_details_div flex-column " style={{ alignItems: "normal" }}>
                                  <h3>Color</h3>
                                  <p className="text-left pt-2" style={{ fontWeight: 500, fontSize: "18px" }}>
                                      { carData ? carData.exteriorColor.join(", ") : "" }
                                  </p>
                              </div>
                              <div className="col-lg-6 buy_details_div flex-column" style={{ alignItems: "normal" }} >
                                  <h3>Registration City</h3>
                                  <p className="text-left pt-2" style={{ fontWeight: 500, fontSize: "18px" }}>
                                    {carData ? carData.registerCity.join(", "):""}
                                  {/* {carData ? carData.registerCity.map((city) => {
                                      return <span>{city}{city.length - 1 ? ", " : " "}</span>;
                                  }):""} */}
                                </p>
                              </div>
                              
                          </div>
                      </div>

                      <div className='sale_detail_notes container pl-0' >
                          <div className="row">
                              <div className="col-sm-12 pl-0" >
                                  <div>
                                      <h1 className='pb-3'>Notes</h1>
                                      <p>{carData?carData.notes : ""}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              <div className="col-lg-4 col-sm-12">
                  <div className="sale_details_side_data">
                      <div className="sale_details_side_div">
                          <div className="side_details_top_div">
                              <div className='py-2'>
                                  <p
                                      style={{ fontSize: "16px", fontWeight: "500", letterSpacing: "0.34px", color: "#7F7F7F", paddingBottom: "15px" }}
                                  >Posted By</p>
                                  <h1>{carData ? carData.name : null}</h1>
                              </div>
                              <div className='d-flex py-2'>
                                  <img alt="suz" className='mr-2 email_logo' />
                                  <p>{carData ? carData.email : null}</p>
                              </div>
                              <div className='d-flex pb-4'>
                                  <img alt="suz" className='mr-2 contactNo_logo' />
                                  <p >+{carData ? carData.contactNo : null}</p>
                              </div>
                          </div>

                          {/* Map area  */}
                          <div className="sale_details_side_map">
                              <div className="side_map_div d-flex ">
                                  <div className='pr-3 pt-1'>
                                      <img className='map_logo' alt="suz" />
                                  </div>
                                  <div className='d-flex flex-column '>
                                      <div>
                                          <p style={{ fontWeight: 600, fontSize: "16px" }}>{carData ? carData.address : null} </p>
                                      </div>
                                      <div className='mt-3'>
                                          <a href='/' className='mt-3'>View Directions</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {/* End of map div  */}

                          {/* Start of Social Div  */}
                          <div className="sale_details_side_social">
                              <div className="request_number_div d-flex justify-content-between ">
                                  <div>
                                      <h1>Request Number</h1>
                                  </div>
                                  <div>
                                      RN123412354532
                                  </div>
                              </div>
                              <hr />

                              <div className="details_social_icons d-flex flex-column align-content-center ">
                                  <div className='py-3 my-2'>
                                      <h1 className='text-center'>Share Now</h1>
                                  </div>
                                  <div className='social_logos d-flex align-items-center justify-content-around'>
                                      <img className='messenger_logo' alt='...' />
                                      <img className='facebook_logo' alt='...' />
                                      <img className='insta_logo' alt='...' />
                                      <img className='whatsapp_logo' alt='...' />
                                  </div>
                              </div>
                          </div>
                          {/* End of Social Div  */}

                      </div>
                      <div className='sale_detail_close_button'>
                      <button className='close_button'
                                    onClick={handleCloseCar} 
                              >
                                  {carData?carData.active?"Close Request":"Closed":null}
                              </button>
                      </div>

                  </div>
              </div>
              </div>
          </section>
    </div>
  )
}

export default BuyDetail