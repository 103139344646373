/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./buy_style.css";
import "../../App.css";
// import "./sell_modal.css";
import BuyModal from "./BuyModal";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { InputLabel, OutlinedInput } from "@material-ui/core";
import BuySubmit from "./BuySubmit";
import { useGlobalContext } from "../../context/DataContext";
import axios from "axios";
import pkCities from "./pk";
import { Link } from "react-router-dom";
import ErrorMessage from "../Other/ErrorMessage";
// import SellModal from "./SellModal";
const numWords = require("num-words");

const BuyCar = () => {
  // Global Context
  const {
    handleNext,
    setHandleNext,
    handleNext2,
    setHandleNext2,
    divHandle,
    setDivHandle,
  } = useGlobalContext();

  // All States

  const [years, setYears] = React.useState([]);
  const [showBottomText, setShowBottomText] = React.useState(false);

  // Handle Dropdown Selection
  const [selectOption, setSelectOption] = React.useState("");
  const [selectExt, setSelectExt] = React.useState("");
  const [selectTrans, setSelectTrans] = React.useState("");
  const [selectPriorty, setSelectPriorty] = React.useState("");
  const [selectAvailable, setSelectAvailable] = React.useState("");
  const [selectFrom, setSelectFrom] = React.useState("");
  const [selectTo, setSelectTo] = React.useState("");
  const [minBudget, setMinBudget] = React.useState("");
  const [maxBudget, setMaxBudget] = React.useState("");
  const [changeDiv, setChangeDiv] = React.useState(false);
  const [sendBuy, setSendBuy] = React.useState({});

  // Handle the next click function
  const [citiesNames, setCities] = React.useState([]);

  // convert number to words
  const [minBudgetWords, setMinBudgetWords] = React.useState("");
  const [maxAmountWords, setMaxAmountWords] = React.useState("");

  // Available Car and Select Car
  const [availableCar, setAvailableCar] = React.useState([]);
  const [selectCar, setSelectCar] = React.useState({
    make: "make",
    model: "model",
    version: "version",
  });

  //  Handle Upload Pics
  const [selectedFiles, setSelectedFiles] = React.useState();
  const [preview, setPreview] = React.useState([]);

  // Select Multiple Cities Code
  const [personName, setPersonName] = React.useState([]);
  const [extColor, setExtCol] = React.useState([]);

  // send data to submit component
  const [contact, setContact] = React.useState({
    name: "",
    email: "",
    number: "",
    city: "",
    address: "",
    notes: "",
  });

  // End of all states
  /////////////////////////////////////////////////////////

  // Get all years from 1940 to current year
  useEffect(() => {
    let years = function (startYear) {
      var currentYear = new Date().getFullYear(),
        years = [];
      startYear = startYear || 1940;
      while (startYear <= currentYear) {
        years.push(startYear++);
      }

      years.sort(function (a, b) {
        return b - a;
      });
      return years;
    };
    setYears(years(1940));
  }, []);

  // Show element on media query
  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowBottomText(true);
    }
  }, []);

  // Handle Div Changes
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState("");


  const handle_next = () => {
    if(selectFrom === "" || selectTo === "" || personName =="" || selectTrans ==="" ){
      setError(true);
      setErrorData("Please fill all the fields");
      return
    }
    setHandleNext(false);
  };

  const handleClick1 = () => {
   
    setHandleNext(true);
  };
  const handleClick2 = () => {
    setHandleNext2(true);
  };

  const handle_next2 = () => {
    if(extColor === "" || minBudget =="" || maxBudget===""|| selectAvailable == "")
    {
      setError(true);
      setErrorData("Please fill all the fields");
      return
    }
    if (minBudget > maxBudget) {
      setError(true);
      setErrorData("Max budget cannot be less than min budget");
    setMaxBudget(0);
    setMinBudget(minBudget);
    return;
  }
  if (minBudget < 0) {
    alert("Min budget cannot be less than 0");
    setMinBudget(0);
    setMaxBudget(0);
    return;
  }
  if (maxBudget < 0) {
    alert("Max budget cannot be less than 0");
    setMaxBudget(0);
    setMinBudget(0);
    return;
  } else {
      setHandleNext(false);
      setHandleNext2(false);
    }
  };

  // Change Max numbers to words
  const handleChangeMax = (event) => {
    event.target.value.length > 9
      ? alert("Please Enter valid number")
      : setMaxBudget(event.target.value);
    setMaxAmountWords(numWords(event.target.value));
    setTimeout(() => {
      setMaxAmountWords("");
    }, 3000);
  };

  // Change Min numbers to words
  const handleChangeMinBudget = (event) => {
    event.target.value.length > 9
      ? alert("Please Enter valid number")
      : setMinBudget(event.target.value);
    setMinBudgetWords(numWords(event.target.value));
    setTimeout(() => {
      setMinBudgetWords("");
    }, 3000);
  };

  useEffect(() => {
    if (!selectedFiles) {
      setPreview([]);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFiles);
    setPreview([...preview, objectUrl]);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFiles]);

  // End of handle upload pics

  const handleChange = (event) => {
    setSelectOption(event.target.value);
  };
  const handleChange2 = (event) => {
    setSelectExt(event.target.value);
  };
  const handleChange3 = (event) => {
    setSelectTrans(event.target.value);
  };
  const handleChange4 = (event) => {
    setSelectPriorty(event.target.value);
  };
  const handleChange5 = (event) => {
    setSelectAvailable(event.target.value);
  };
  const handleChangeFrom = (event) => {
    setSelectFrom(event.target.value);
  };
  const handleChangeTo = (event) => {
    setSelectTo(event.target.value);
  };

  // Handle Select Car
  const handleChange6 = (value, name) => {
    if (name === "year") {
      setSelectCar({
        ...selectCar,
        [name]: value,
        make: "make",
        model: "model",
        version: "version",
      });
    } else if (name === "make") {
      setSelectCar({
        ...selectCar,
        [name]: value,
        model: "model",
        version: "version",
      });
    } else if (name === "model") {
      setSelectCar({ ...selectCar, [name]: value, version: "version" });
    } else setSelectCar({ ...selectCar, [name]: value });
  };

  // Get Available Cars from API
  const getAvailableCars = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}cars/availablecars`
    );
    return res.data.data;
  };

  useEffect(() => {
    setCities(pkCities.map((city) => city.city));

    getAvailableCars().then((res) => {
      setAvailableCar(res);
    });
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const colorsNames = [
    "Red",
    "Blue",
    "Black",
    "White",
    "Silver",
    "Yellow",
    "Green",
    "Brown",
    "Gray",
    "Orange",
    "Purple",
    "Pink",
    "Gold",
    "Turquoise",
    "Beige",
    "Maroon",
    "Navy",
    "Lavender",
    "Copper",
    "Burgundy",
    "Lime",
    "Charcoal",
    "Olive",
    "Apricot",
    "Cyan",
    "Other",
    "Not Sure",
  ];

  const handleChangeCities = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeColors = (event) => {
    const {
      target: { value },
    } = event;
    setExtCol(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // End of Select Multiple Cities Code

  const handleContact = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContact({ ...contact, [name]: value });
  };

  const handleBuyDivChange = () => {
    if (
      contact.name === "" ||
      contact.number === "" ||
      contact.address === "" ||
      contact.city === ""
    ) {
      setError(true);
      setErrorData("Please fill all the fields");
      return;
    }
    setDivHandle(true);
    setSendBuy({
      selectFrom,
      selectTo,
      personName,
      extColor,
      selectTrans,
      selectAvailable,
      minBudget,
      maxBudget,
      selectCar,
      contact,
    });
  };

  return (
    <>
      <ErrorMessage open={error} setOpen={setError} data={errorData} />
      {/* Start of Sell Car  */}
      <div className="nav-wrapper sell_your_car">
        <div className="image-wrapper">
          <Link to="/">
            <img src="assets/images/logo.png" width="222px" alt="" />
          </Link>
        </div>
        <div className="text-wrapper">
          <h1
            style={{
              fontSize: "24px",
              paddingLeft: "16rem",
              paddingTop: "20px",
              fontWeight: 700,
            }}
          >
            Buy your car in three easy steps
          </h1>
        </div>
      </div>
      <div
        className="position-relative"
        style={{
          minHeight: "88vh",
        }}
      >
        {divHandle === false ? (
          <div className="container-fluid" id="sellyourcar">
            <div className="row justify-content-center mt-0">
              <div className="col-11 col-sm-9 col-md-7 col-lg-12 text-center p-0 mt-2 mb-2">
                <div className="px-0 pt-1 pb-0 mt-1">
                  {/* <!-- <h2><strong>Sign Up Your User Account</strong></h2>
                    <p>Fill all form field to go to next step</p> --> */}
                  <div className="row buy_a_car m-0">
                    <div className="col-md-12 col-12 mx-0 p-0">
                      <form id="msform">
                        {/* <!-- fieldsets --> */}
                        {handleNext ? (
                          <fieldset id="fieldset-1">
                            <div className="form-card container">
                              <p>STEP 1</p>
                              <h3 className="fs-title">
                                Enter your car information
                              </h3>
                              <div
                                className="row d-flex sell_a_car_row"
                                style={{ marginBottom: "60px" }}
                              >
                                <div
                                  className="col-lg-6 col-sm-12 make_button"
                                  style={{ width: "620px" }}
                                >
                                  <div className="d-flex set_availability">
                                    <div
                                      className="flex-1"
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        className=""
                                        style={{ marginTop: "10px" }}
                                      >
                                        <label className="pay">
                                          Select Year Range
                                          <span className="text-warning">
                                            *
                                          </span>
                                        </label>
                                      </div>
                                      <p
                                        className="text-para-22"
                                        style={
                                          preview.length > 0
                                            ? { top: "112px" }
                                            : null
                                        }
                                      >
                                        From
                                      </p>
                                      <div className="">
                                        <div className="d-flex years_select_div">
                                          <FormControl
                                            sx={{ m: 1, minWidth: 120 }}
                                          >
                                            <Select
                                              value={selectFrom}
                                              onChange={handleChangeFrom}
                                              displayEmpty
                                              inputProps={{
                                                "aria-label": "Without label",
                                              }}
                                              className="list-dt-1"
                                              id="from"
                                              name="expmonth"
                                              required={true}
                                            >
                                              <MenuItem value="">
                                                Select Year
                                              </MenuItem>
                                              {years.map((item, index) => {
                                                return (
                                                  <MenuItem value={item}>
                                                    {item}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                          <div style={{ position: "relative" }}>
                                            <p className="text-para-33">To</p>
                                            <FormControl
                                              sx={{ m: 1, minWidth: 120 }}
                                            >
                                              <Select
                                                value={selectTo}
                                                onChange={handleChangeTo}
                                                displayEmpty
                                                inputProps={{
                                                  "aria-label": "Without label",
                                                }}
                                                className="list-dt-1"
                                                id="to"
                                                name="expmonth"
                                                required={true}
                                                disabled={
                                                  selectFrom === ""
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <MenuItem value="">
                                                  Select Year
                                                </MenuItem>
                                                {years.map((item, index) => {
                                                  if (item > selectFrom) {
                                                    return (
                                                      <MenuItem value={item}>
                                                        {item}
                                                      </MenuItem>
                                                    );
                                                  }
                                                })}
                                              </Select>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-lg-6 col-sm-12 make_button"
                                  style={{ width: "620px", marginTop: "10px" }}
                                >
                                  <div className="">
                                    <label className="pay">
                                      Make
                                      <span className="text-warning">*</span>
                                    </label>
                                    <BuyModal
                                      yearFrom={selectFrom}
                                      yearTo={selectTo}
                                      fun={handleChange6}
                                      value={availableCar}
                                      selectCar={selectCar}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex buy_a_car_bottom ">
                                <div className="col-lg-6 col-sm-12 p-0 ">
                                  <div className="position-relative">
                                    <label className="pay">
                                      Select Multiple Cities
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>
                                  <FormControl
                                    sx={{ m: 1, width: 300 }}
                                    className="mul_cities position-relative"
                                  >
                                    <InputLabel id="demo-multiple-name-label">
                                      Select city
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="demo-multiple-name"
                                      multiple
                                      value={personName}
                                      onChange={handleChangeCities}
                                      MenuProps={MenuProps}
                                      input={<OutlinedInput label="Name" />}
                                    >
                                      {citiesNames.map((name) => (
                                        <MenuItem
                                          style={{ paddingLeft: "50px" }}
                                          key={name}
                                          value={name}
                                        >
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>

                                <div
                                  className="col-lg-6 col-sm-12 p-0"
                                  style={{ marginRight: "12px" }}
                                >
                                  <div className="">
                                    <label className="pay">
                                      Transmission
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>
                                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                      value={selectTrans}
                                      onChange={handleChange3}
                                      displayEmpty
                                      className="list-dt-4"
                                    >
                                      <MenuItem value="">
                                        Select Transmission
                                      </MenuItem>
                                      <MenuItem value="automatic">
                                        Automatic
                                      </MenuItem>
                                      <MenuItem value={"manual"}>
                                        Manual
                                      </MenuItem>
                                      <MenuItem value={"any"}>Any</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>

                            <input
                              type="button"
                              name="next"
                              className="next action-button pos-4 bg-gray"
                              value="Save & Continue"
                              onClick={handle_next}
                            />
                          </fieldset>
                        ) : null}

                        {handleNext2 ? (
                          <fieldset id="fieldset-2">
                            <div className="form-card container">
                              {/* <span className="active prog-btn" id="account" onClick={handleClick1}>
                      <a className="achor-wrap" href="#fieldset-1"
                        ><strong className="strong-wrapp">Arrow</strong></a>
                    </span> */}
                              <p>STEP 2</p>
                              <h2 className="fs-title">
                                Select color and budget
                              </h2>

                              <div className="row p-0 m-0">
                                <div className="col-lg-6 col-sm-12 p-0">
                                  <div className="">
                                    <label className="pay">
                                      Multiple Exterior Color
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>
                                  <FormControl
                                    sx={{ m: 1, width: 300 }}
                                    className="mul_cities"
                                  >
                                    <InputLabel
                                      id="demo-multiple-name-label"
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      Select colors
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="demo-multiple-name"
                                      multiple
                                      value={extColor}
                                      onChange={handleChangeColors}
                                      MenuProps={MenuProps}
                                      input={<OutlinedInput label="Name" />}
                                    >
                                      {colorsNames.map((name) => (
                                        <MenuItem
                                          style={{ paddingLeft: "50px" }}
                                          key={name}
                                          value={name}
                                        >
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col-lg-6 p-0">
                                  <div className="flex-2">
                                    <div className="">
                                      <label className="pay">
                                        Budget
                                        <span className="text-warning">*</span>
                                      </label>
                                    </div>
                                    <div className="d-flex">
                                      <div className="position-relative">
                                        <div className="form-group d-flex">
                                          <p className="min-label">MIN</p>
                                          <input
                                            type="text"
                                            className="input-wrapp"
                                            id="minBudget"
                                            placeholder="Rs"
                                            value={minBudget}
                                            onChange={handleChangeMinBudget}
                                          />

                                          {minBudgetWords.length > 0 ? (
                                            <div className="guess_number guess_min">
                                              <span>{minBudgetWords}</span>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="position-relative">
                                        <div className="form-group d-flex">
                                          <p className="max-label">MAX</p>
                                          <input
                                            type="text"
                                            className="input-wrapp-2"
                                            id="maxBudget"
                                            placeholder="Rs"
                                            value={maxBudget}
                                            onChange={handleChangeMax}
                                          />
                                          {maxAmountWords.length > 0 ? (
                                            <div className="guess_number guess_min">
                                              <span>{maxAmountWords}</span>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="wrap-pad m-0 ">
                                <div className="wrapper">
                                  <div className="">
                                    <label className="pay ">
                                      Urgency
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>
                                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                      value={selectAvailable}
                                      onChange={handleChange5}
                                      displayEmpty
                                      className="list-dt-5"
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <MenuItem
                                        value=""
                                        style={{ fontSize: "13px !important" }}
                                      >
                                        Please Select One Option                                        
                                      </MenuItem>
                                      <MenuItem
                                        value="High: Need a car on urgent basis"
                                        style={{ fontSize: "13px !important" }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          High
                                        </span>
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</> Need a car on urgent basis
                                      </MenuItem>
                                      <MenuItem
                                        value={
                                          "Medium : Need a car within 15 days"
                                        }
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Medium{" "}
                                        </span>{" "}
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</> Need a car within 15 days
                                      </MenuItem>
                                      <MenuItem
                                        value={
                                          "Low : Need a car in best condition possible"
                                        }
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Low{" "}
                                        </span>{" "}
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</> Need a car in best condition
                                        possible
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>

                            <input
                              type="button"
                              name="next"
                              className="next action-button pos-5 bg-gray"
                              value="Save & Continue"
                              onClick={handle_next2}
                              style={
                                preview.length > 0
                                  ? { top: "56rem" }
                                  : { color: "white" }
                              }
                            />
                          </fieldset>
                        ) : (
                          <fieldset id="fieldset-3">
                            <div className="form-card container">
                              <div className="text-wrap">
                                <p>STEP 3</p>
                                <h6 className="fs-title">
                                  Contact information
                                </h6>
                              </div>

                              <div
                                className="col-lg-12 col-sm-12 d-flex contact_divs_column p-0"
                                style={{ marginBottom: "50px", padding: "0px" }}
                              >
                                <div className="wrap-1">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    Name <span className="text-warning">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type Full Name"
                                    required={true}
                                    className="input-11"
                                    id="name"
                                    name="name"
                                    value={contact.name}
                                    onChange={handleContact}
                                  />
                                </div>
                                <div className="wrap-2">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    Email{" "}
                                    <span
                                      className=" "
                                      style={{ color: "black !important" }}
                                    >
                                      (optional)
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type Your Email"
                                    className="input-11"
                                    id="email"
                                    name="email"
                                    value={contact.email}
                                    onChange={handleContact}
                                  />
                                </div>
                                <div className="wrap-3">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    Contact Number
                                    <span className="text-warning">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type Your Contact Number"
                                    required={true}
                                    className="input-11"
                                    id="number"
                                    name="number"
                                    value={contact.number}
                                    onChange={handleContact}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-12 d-flex address_div_mobile"
                                style={{ marginBottom: "20px" }}
                              >
                                <div className="wrap-3">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    City <span className="text-warning">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Select City"
                                    required={true}
                                    className="input-11"
                                    id="city"
                                    name="city"
                                    value={contact.city}
                                    onChange={handleContact}
                                  />
                                </div>
                                <div className="wrap-3">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    Address{" "}
                                    <span className="text-warning">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type Your Address"
                                    required={true}
                                    className="input-22"
                                    id="address"
                                    name="address"
                                    value={contact.address}
                                    onChange={handleContact}
                                  />
                                </div>
                              </div>
                              <div
                                className="wrap-3 buy_notes_area"
                                style={{ marginTop: "50px" }}
                              >
                                <label
                                  className="pay"
                                  style={{ display: "block" }}
                                >
                                  Notes (Optional)
                                  <span className="text-warning"></span>
                                </label>
                                <textarea
                                  name="notes"
                                  value={contact.notes}
                                  rows="4"
                                  cols="50"
                                  placeholder="Add Notes"
                                  id="notes"
                                  required={true}
                                  onChange={handleContact}
                                ></textarea>
                              </div>
                            </div>
                            {/* <!-- <input type="submit" id="myElement" className="next action-button review-1 submit"
                                        value="Save & review" /> --> */}

                            <button
                              className="next action-button review-1  "
                              onClick={handleBuyDivChange}
                              type="button"
                            >
                              Save & review
                            </button>
                          </fieldset>
                        )}

                        {/* <!-- progressbar --> */}
                        <div
                          style={{
                            position: "fixed",
                            bottom: "0",
                            left: "0",
                            width: "100vw",
                            backgroundColor: "white",
                          }}
                        >
                          <ul id="progressbar">
                            <li
                              className="active prog-btn"
                              id="account"
                              onClick={handleClick1}
                            >
                              <a className="achor-wrap" href="#fieldset-1">
                                {showBottomText ? null : (
                                  <strong className="strong-wrapp">
                                    Car Information
                                  </strong>
                                )}
                              </a>
                            </li>
                            <li
                              className={
                                handleNext ? "prog-btn" : "prog-btn active"
                              }
                              id="personal"
                              onClick={handleClick2}
                            >
                              <a className="achor-wrap" href="#fieldset-2">
                                {showBottomText ? null : (
                                  <strong className="strong-wrapp">
                                    Set Priority, Availability and Upload Photos
                                  </strong>
                                )}
                              </a>
                            </li>
                            <li
                              className={
                                handleNext2 ? "prog-btn" : "prog-btn active"
                              }
                              id="payment"
                            >
                              <a className="achor-wrap" href="#fieldset-3">
                                {showBottomText ? null : (
                                  <strong className="strong-wrapp">
                                    Contact Information
                                  </strong>
                                )}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <BuySubmit data={sendBuy} />
        )}
      </div>
    </>
  );
};

export default BuyCar;
