import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import "../buy/Modal.css";

export default function SimpleModal(props) {
  const { value, selectCar, fun, yearFrom, yearTo } = props;
  const [open, setOpen] = useState(false);
  const [carsData, setCarsData] = useState({ makes: [], model: [], version: [] });
  const [currentStep, setCurrentStep] = useState(0); // State to track the current step
  const handleOpen = () => {
    setCurrentStep(0); // Reset step when opening
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange6 = (value, name) => {
    fun(value, name);
  };

  useEffect(() => {
    let makes = [];
    value.forEach((item) => {
      if (makes.findIndex(m => item.make.toUpperCase() === m.make) === -1) {
        makes.push({ make: item.make.toUpperCase(), to: item.year.to, from: item.year.from });
      } else {
        let index = makes.findIndex(m => item.make.toUpperCase() === m.make);
        if (makes[index].to < item.year.to) makes[index].to = item.year.to;
        if (makes[index].from > item.year.from) makes[index].from = item.year.from;
      }
    });

    let model = [];
    value.forEach((item) => {
      if (model.findIndex(m => item.model === m.model) === -1) {
        model.push({ model: item.model, to: item.year.to, from: item.year.from, make: item.make.toUpperCase() });
      } else {
        let index = model.findIndex(m => item.model === m.model);
        if (model[index].to < item.year.to) model[index].to = item.year.to;
        if (model[index].from > item.year.from) model[index].from = item.year.from;
      }
    });

    let version = [];
    value.forEach((item) => {
      if (version.findIndex(m => item.version === m.version) === -1) {
        version.push({ version: item.version, to: item.year.to, from: item.year.from, make: item.make.toUpperCase(), model: item.model });
      } else {
        let index = version.findIndex(m => item.version === m.version);
        if (version[index].to < item.year.to) version[index].to = item.year.to;
        if (version[index].from > item.year.from) version[index].from = item.year.from;
      }
    });

    setCarsData({ makes, model, version });
  }, [value]);

  const [searchMake, setSearchMake] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [searchVersion, setSearchVersion] = useState("");

  const mappingMake = carsData.makes.map(car => (
    car.make.substring(0, searchMake.length).toUpperCase() === searchMake.toUpperCase() &&
      Number(car.to) >= yearFrom && Number(car.from) <= yearTo ? (
      <div
        className="p-2 list"
        style={selectCar.make === car.make ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }}
        onClick={() => handleChange6(car.make, "make")}
        key={car.make}
      >
        <div className="d-flex" style={{ width: "300px", paddingLeft: "20px" }}>
          {selectCar.make === car.make ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" /> : null}
          <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.make}</h5>
        </div>
      </div>
    ) : null
  ));

  const mappingModel = carsData.model.map(car => (
    car.model.substring(0, searchModel.length).toUpperCase() === searchModel.toUpperCase() &&
      car.make === selectCar.make && Number(car.to) >= yearFrom && Number(car.from) <= yearTo ? (
      <div
        className="p-2 list"
        style={selectCar.model === car.model ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }}
        onClick={() => handleChange6(car.model, "model")}
        key={car.model}
      >
        <div className="d-flex" style={{ width: "300px", paddingLeft: "20px" }}>
          {selectCar.model === car.model ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" /> : null}
          <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.model}</h5>
        </div>
      </div>
    ) : null
  ));

  const mappingVersion = carsData.version.map(car => (
    car.version.substring(0, searchVersion.length).toUpperCase() === searchVersion.toUpperCase() &&
      car.model === selectCar.model && car.make === selectCar.make && Number(car.to) >= yearFrom && Number(car.from) <= yearTo ? (
      <div
        className="p-2 list"
        style={selectCar.version === car.version ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }}
        onClick={() => handleChange6(car.version, "version")}
        key={car.version}
      >
        <div className="d-flex" style={{ width: "300px", paddingLeft: "20px" }}>
          {selectCar.version === car.version ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" /> : null}
          <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.version}</h5>
        </div>
      </div>
    ) : null
  ));

  return (
    <div>
      {
        yearFrom && yearTo ?
          <Button
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              float: "left", display: "flex",
              justifyContent: "left",
              fontFamily: " 'poppins', sans-serif !important",
              background: "#f5f6f7"
            }}
            onClick={handleOpen}
          >
            {selectCar.make + ", " + selectCar.model + ", " + selectCar.version}
          </Button> :
          <Button
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              float: "left", display: "flex",
              justifyContent: "left",
              fontFamily: " 'poppins', sans-serif !important",
              background: "#f5f6f7",
              opacity: "0.5"
            }}
            disabled
          >
            Select year range to select car
          </Button>
      }
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >

        <div className="modal-container">
          <div className="container modal-subcontainer">
            <div className="row" id="rowArea">
              {/* Step 1: Select Make */}
              {currentStep === 0 && (
                <div className="col option border border-right-1" style={selectCar.make !== 'make' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption1">
                  <h5 className="mt-4 mb-3 fw-bold">Select Make</h5>
                  <div className="search border border-1">
                    <img src="./assets/search.png" className="search-icon" alt="" />
                    <input
                      type="text"
                      placeholder="Search make"
                      className="searchArea pl-2"
                      onChange={(e) => setSearchMake(e.target.value)}
                    />
                  </div>
                  <div>
                    {mappingMake}
                  </div>
                </div>
              )}

              {/* Step 2: Select Model */}
              {currentStep === 1 && selectCar.make !== 'make' && (
                <div className="col option border border-right-1" style={selectCar.model !== 'model' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption3">
                  <h5 className="mt-4 mb-3 fw-bold">Select Model</h5>
                  <div className="search border border-1 rounded">
                    <img src="./assets/search.png" className="search-icon" alt="" />
                    <input
                      type="text"
                      className="searchArea pl-2"
                      placeholder="Search model"
                      onChange={(e) => setSearchModel(e.target.value)}
                    />
                  </div>
                  <div>
                    {mappingModel}
                  </div>
                </div>
              )}

              {/* Step 3: Select Version */}
              {currentStep === 2 && selectCar.model !== 'model' && (
                <div className="col option border border-right-1" style={selectCar.version !== 'version' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption2">
                  <h5 className="mt-4 mb-3 fw-bold">Select Version</h5>
                  <div className="search border border-1 rounded">
                    <img src="./assets/search.png" className="search-icon" alt="" />
                    <input
                      type="text"
                      className="searchArea pl-2"
                      placeholder="Search version"
                      onChange={(e) => setSearchVersion(e.target.value)}
                    />
                  </div>
                  <div>
                    {mappingVersion}
                  </div>
                </div>
              )}
            </div>

            {/* Navigation Buttons */}
            <div className="modal-footer">
              {currentStep > 0 && (
                <Button
                  variant="outlined"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Back
                </Button>
              )}
              {currentStep < 2 ? (
                <Button
                  variant="contained"
                  onClick={() => setCurrentStep(currentStep + 1)}
                  disabled={(currentStep === 0 && selectCar.make === 'make') || (currentStep === 1 && selectCar.model === 'model')}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleClose}
                >
                  Close
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
