import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../Other/ErrorMessage';



const UserLogin = () => {

    const navigate = useNavigate();


    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };


    const [login, setLogin] = React.useState({
        email: "",
        password: "",
    });
    const [error, setError] = React.useState(false);
    const [errorData, setErrorData] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);


    const handleLoginData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLogin({ ...login, [name]: value });
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        const { email, password } = login;

        try {
            var data = JSON.stringify({
                "email": email,
                "password": password
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}admin/employeelogin`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            const res = await axios(config);

            if(res.data.code === 400){
                setErrorData(res.data.message);
                setError(true);
                setOpen(true);
            }

           
            if (res.data.code === 200) {
                localStorage.removeItem('adminToken');
                localStorage.setItem('user', JSON.stringify(res.data));
                navigate('/carsDashboard');
            }
            
            
            setLoading(false);
        } catch (error) {
            alert('Something went wrong');

        }

    }

    // useEffect(() => {
    //     if (userInfo) {
    //         navigate('/carsDashboard')
    //     }
    // }, [userInfo, navigate])


    return (
        <div>
            {error === true ? <ErrorMessage open={open} setOpen={setOpen} data={errorData} /> : null}
            <div className="row login_wrapper">
                <div className="col-lg-5 col-sm-12">
                    <div className="login_image">
                        <img src="assets/dashboard_images/banner.png" alt='...' />
                    </div>
                </div>
                <div className="col-lg-7 col-sm-12">
                    <div className="login_form">
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                            <div className="login_form_header">
                                <h1>Login to your account</h1>
                            </div>
                            <div className='login_inputs pt-2'>
                                <form className="login-form" method="POST">
                                    <div className="form-input-material">
                                        <input type="email" name="email" id="username" placeholder=" " autoComplete=" " required className="form-control-material"
                                            onChange={handleLoginData}
                                            value={login.name}
                                        />
                                        <label for="username">Email</label>
                                    </div>
                                    <div className="form-input-material position-relative">
                                        <input type={passwordShown ? "text" : "password"} name="password" id="password" placeholder=" " autoComplete="off" required className="form-control-material"
                                            onChange={handleLoginData}
                                            value={login.password}
                                            style={{ width: '330px !important' }}
                                        />
                                        <i className='fa fa-eye position-absolute'
                                            style={{
                                                right: "120px",
                                                top: "25px",
                                                fontSize: "20px",
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor: "#ed1c24",
                                                borderRadius: "50%",
                                                color: "#fff"

                                            }}
                                            onClick={togglePassword}
                                        ></i>
                                        <label for="password">Password</label>
                                        <button className='position-absolute'>Forgot?</button>
                                    </div>
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <button
                                            type="submit" className="login_button"
                                            onClick={handleLoginSubmit}
                                        >Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserLogin