import { Slide } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../../context/DataContext';
import BuyData from '../buydashboard/BuyData'
import "../dashboard_style.css"
import SalesData from '../salesdashboard/SalesData';

const ClosedRequest = () => {

    const { totalSales, totalBuy } = useGlobalContext();

    const [selectSaved, setSelectSaved] = React.useState(false);

    const handleChangeSaved = () => {
        console.log("clicked");
        setSelectSaved(true);
    }
    const handleChangeSaved1 = () => {
        setSelectSaved(false);
    }


    const [SalesData, setSalesData] = React.useState([]);
    const [BuyAllData, setBuyData] = React.useState([]);

    const getData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}cars/all-sell-cars`);
        console.log(res.data);
        return res.data.data;
    }
    const getBuyData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}cars/all-buy`);
        console.log(res.data);
        return res.data.data;
    }

        
    

    useEffect(() => {
        getData().then((data) => {
            setSalesData(data);
        });
    }, []);

    useEffect(() => {
        getBuyData().then((data) => {
            setBuyData(data);

        });
    }, []);



    const carInfoSales = SalesData.map((car) => { return car.active === false? <div className="d-flex align-items-center justify-content-center">
    <Link to={"/saledetail/" + car._id} key={car._id}>
        <div className="sales_data_wrapper col-12 p-0" >
            <div className="sales_data d-flex justify-content-between" >
                <div className="sales_data_image">
                    <img src="assets/dashboard_images/alto.png" alt="suz" />
                </div>
                <div className="sales_data_details" key={car._id}>
                    <h2 className="text-left d-inline">{car.make.make} {car.make.model} {car.make.year} </h2>-<span> {car.make.version}</span>
                    <div className="d-flex ">
                        <p className="text-left pr-2 sales_data_city">{car.registerCity}</p>
                        <p className="text-left">5 mins</p>
                    </div>
                </div>
                <div className="sales_data_second_content">
                    <p className="text-left text-danger text-uppercase "
                        style={{ fontWeight: "600", letterSpacing: "0.12px" }}
                    >{car.priority.split(' ').slice(0, 2).join(' ')}</p>
                </div>
                <div className="sales_data_second_content sales_data_km ml-3">
                    <img src="assets/dashboard_images/km.png" className='mr-2 search_logo' alt='km' />
                    <p className="text-left">{car.milage} km</p>
                </div>
                <div className="sales_data_second_content " style={{ width: "110px" }}>
                    <img src="assets/dashboard_images/auto.png" className='mr-2 search_logo' alt='km' />
                    <p className="text-left">{car.transmission}</p>
                </div>
                <div className="sales_data_second_content sales_data_color 
                            d-flex justify-content-center align-items-center" style={{ width: "100px", boxShadow: "0px 5px 10px #ded1d1" }}>
                    <span style={{
                        background: car.exteriorColor,
                    }} ></span>
                    <p className="text-left">{car.exteriorColor}</p>
                </div>


            </div>

        </div>
    </Link>
</div>:null
});

const [salesCarsLength, setSalesCarsLength] = React.useState(0);
const [buyCarsLength, setBuyCarsLength] = React.useState(0);

useEffect(() => {
    setSalesCarsLength(
        SalesData.filter((car) => car.active === false).length
    );
}, [SalesData]);

useEffect(() => {
    setBuyCarsLength(
        BuyAllData.filter((car) => car.active === false).length)
}, [BuyAllData]);

const carInfoBuy = BuyAllData.map((car) => { return car.active === false? <div className="d-flex align-items-center justify-content-center">
<Link to={"/buydetail/" + car._id} key={car._id}>
        <div className="purchase_data buy_hover_div d-flex justify-content-between pb-4 " style={{ borderBottom: "1px solid lightgrey", padding: "22px 0px" }} key={car._id}>
            <div className="purchase_data_image" style={{
                width: "40px",
                height: "40px",
                marginRight: "20px"
            }}>
                <img src="assets/dashboard_images/alto.png" alt="suz" />
            </div>
            <div className="purchase_data_details" style={{ width: "315px" }}>
                <h2 className="text-left d-inline pb-3">{car.make.make} {car.make.model}
                    <span> {car.from}</span> - <span>{car.to} </span>
                    <span> {car.make.version}</span> or <span>X</span></h2>
                <div className="d-flex ">
                    <p className="text-left pr-2 purchase_data_city text-danger">
                        <span>{car.minBudget}</span> - <span>{car.maxBudget}</span>
                        <span style={{ fontWeight: "500", fontSize: "12px" }}> PKR</span>
                    </p>
                    <p className="text-left">5 mins</p>
                </div>
            </div>

            {/* High code  */}
            <div className="purchase_data_second_content d-flex justify-content-center align-items-center"
                style={{ width: "80px" }}
            >
                <p className="text-left text-danger text-uppercase "
                    style={{ fontWeight: "600", letterSpacing: "0.12px" }}
                >{car.urgency.split(' ').slice(0, 1).join(' ')}</p>
            </div>

            {/* Purchase Cities  */}
            <div className="purchase_data_second_content purchase_data_km ml-3">
                <img src="assets/dashboard_images/Group 2211.svg" className='mr-2 search_logo' alt='km' />
                <p className="text-left">
                    {car.city}
                </p>
            </div>
            <div className="purchase_data_second_content " style={{ width: "110px" }}>
                <img src="assets/dashboard_images/auto.png" className='mr-2 search_logo' alt='km' />
                <p className="text-left">{car.transmission}</p>
            </div>
            <div className="purchase_data_second_content purchase_data_color 
                                d-flex justify-content-center align-items-center" style={{
                    width: "100px",
                    boxShadow: "0px 5px 10px #ded1d1"
                }}>
                {
                    car.exteriorColor.map((color, index) => {
                        return <span key={index} className='purchase_color1' style={{ backgroundColor: color }}></span>
                    })
                }


            </div>
        </div> </Link>
</div>:null
});



    return (
        <div>
            <div className="container-fluid saved_request_wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="dashboard_content">
                            <div className="dashboard_content_header">
                                <h3>Closed Request</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard_content_body saved_request_wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="saved_navigation">

                            <div
                                className="d-flex"
                                style={selectSaved === false ? { borderBottom: "2px solid #ed1c24", color: "#000" } : null}
                                onClick={handleChangeSaved1}
                            >
                                <button className='mr-3 d-flex'
                                    style={selectSaved === false ? { color: "#000" } : null}
                                >
                                    <p className='mr-3'>Cars for Sale</p>
                                    <span
                                        className={selectSaved === false ? "bg-danger" : null}
                                        style={{
                                            width: "auto",
                                            height: "23px",
                                            color: "#ffffff",
                                            fontSize: "14px",
                                            background: " #959494 0% 0% no-repeat padding-box",
                                            borderRadius: "5px",
                                            opacity: 1,
                                            padding: "1px 7px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}



                                    >{salesCarsLength}</span>
                                </button>
                            </div>

                            <div
                                className="d-flex"
                                style={selectSaved === true ? { borderBottom: "2px solid #ed1c24", color: "#000" } : null}
                            >
                                <button className='mr-3 d-flex'
                                    onClick={handleChangeSaved}
                                    style={selectSaved === true ? { color: "#000" } : null}
                                >
                                    <p className='mr-3'>Cars Purchase</p>
                                    <span
                                        className={selectSaved === true ? "bg-danger" : null}
                                        style={{
                                            width: "auto",
                                            height: "23px",
                                            color: "#ffffff",
                                            fontSize: "14px",
                                            background: " #959494 0% 0% no-repeat padding-box",
                                            borderRadius: "5px",
                                            opacity: 1,
                                            padding: "1px 7px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}

                                        id="purchaseCount"

                                    >{buyCarsLength}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {
                selectSaved === false ? < div className="container purchase_dashboard_content dashboard_box sales_data_wrapper">
                    <div className="row" style={{ paddingTop: "10px" }}>
                        {/* Start of Side bar code  */}
                        <div className="col-lg-10 " >
                            <div className="purchase_show_content">
                                <div className="bor-wrap">
                                    <div className="d-flex justify-content-end mb-2 mt-3">
                                        <div className="sort-wrapper">
                                            Sort by <a href="/" className="text-danger">Date Closed</a>
                                        </div>
                                    </div>
                                </div>

                                {/* show all the data component  */}
                                <div className=' mb-4'
                                    style={{ width: "100%", height: "20px", borderBottom: "1px solid #DADADA", textAlign: "center" }}>
                                    <span style={{ fontSize: "14px", backgroundColor: "white", padding: "10px 20px", fontWeight: "500", color:"#7F7F7F" }}>
                                        Closed on july 6th 2020
                                    </span>
                                </div>

                                <div className="row">
                                    {carInfoSales}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :

                    < div className="container purchase_dashboard_content dashboard_box purchase_data_wrapper">
                        <div className="row" style={{ paddingTop: "10px" }}>
                            {/* Start of Side bar code  */}
                            <div className="col-lg-10 " >
                                <div className="purchase_show_content">
                                    <div className="bor-wrap">
                                        <div className="d-flex justify-content-end mb-2 mt-3">
                                            <div className="sort-wrapper">
                                                Sort by <a href="/" className="text-danger">Date Posted</a>
                                            </div>
                                        </div>
                                    </div>

                                    {/* show all the data component  */}
                                    <div className=' mb-4'
                                        style={{ width: "100%", height: "20px", borderBottom: "1px solid #DADADA", textAlign: "center" }}>
                                        <span style={{ fontSize: "14px", backgroundColor: "white", padding: "10px 20px", fontWeight: "500", color: "#7F7F7F" }}>
                                            Closed on july 6th 2020
                                        </span>
                                    </div>

                                    <div className="row">
                                        {carInfoBuy}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }


        </div>
    )
}

export default ClosedRequest