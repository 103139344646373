import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Alert from '@material-ui/lab/Alert';

const ErrorMessage = (props) => {
    const { open,setOpen, data } = props;

    

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    // Toast Code 
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <div>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity="error" variant="filled">
                    {data}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ErrorMessage;