import React from 'react'
import "../../App.css";
import { Link } from 'react-router-dom';

const Succesfull = () => {
    // program to generate random strings

    const result = "RN"+Math.floor(Math.random() * (40000000 - 0 + 1)) + 0;
  return (
    <div>
          <div className="container marg-wrapp success_container">
              <div className="row container succes">
                  <div className="col-lg-12">
                      <div className="succes-image">
                          <img src="assets/images/Buy Step 9@2x (1).png" width="266px" height="178px" alt="Buy Step" />
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="succes-text">
                          Your request no <span className="text-danger">({result}) </span> has been submitted successfully!
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="succes-p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                          et dolore magna
                          aliqua. Ut enim ad, quis nostrud exercitation ullamco laboris nisi ut deserunt mollit anim id est
                          laborum.
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <Link className="btn-text" to="/">
                      <div className="succes-btn" style={{fontWeight:600}}>
                              Sell Now                      
                      </div>
                      </Link>
                  </div>
              </div>
          </div>
    </div>
  )
}

export default Succesfull