import React, { useState } from 'react'
import "./admin_style.css";

const EditAccount = () => {

    const [selectEdit, setSelectEdit] = React.useState(false);

    const handleChangeEdit = () => {
        // console.log("clicked");
        setSelectEdit(true);
    }

    const handleChangeEdit1 = () => {
        // console.log("clicked");
        setSelectEdit(false);
    }

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const togglePassword1 = () => {
        setPasswordShown1(!passwordShown1);
    };


  return (
      <div className='account_section'>

        {/* Start of edit Account  */}


          <div className="outer-wrapper-2">
              <div className="inner-wrapper-2">
                  <h1 className="tittle-head">Edit Account</h1>
              </div>
          </div>
          <div className="form">
              <ul className="tab-group">
                  <li className="tab active">
                    <button  className="underlineHover" 
                    
                        style={selectEdit === false? { fontWeight: "600", color: "#ed1c24 !important" }:{color: "lightgrey !important"}}
                        onClick={handleChangeEdit1}>
                        Profile
                    </button></li>
                  <li className="tab"><button  className="underlineHover"
                      style={selectEdit === true ? { fontWeight: "600", color: "#ed1c24 !important" } : { color: "lightgrey !important" }}
                    onClick={handleChangeEdit}
                  >Password</button></li>
              </ul>
              

              <div className="data">
                  {selectEdit === false ? <div id="signup">
                      <div className="top-row">
                          <div className="field-wrap">
                              <div className="logo-profile">
                                  <img src="assets/dashboard_images/profile.png" width="122px" alt="" />
                              </div>
                          </div>

                          <div className="field-wrap" style={{ marginTop: "28px" }}>
                              <h6 className="ps-2">
                                  Your Avatar
                              </h6>
                              <div className="btn-profile">
                                  <a className="btn btn-primary ms-2 nav-btns-2" href="#">
                                      Update
                                  </a>
                              </div>
                          </div>
                      </div>

                      <div className="field-wrap">
                          <p>
                              Name
                          </p>
                          <input type="text" id="pname" required />
                      </div>

                      <div className="field-wrap">
                          <p>
                              Email
                          </p>
                          <input type="email" id="email"  autocomplete="off" />
                      </div>
                      <div className="field-wrap">
                          <p>
                              Contact Number
                          </p>
                          <input type="number" id="contactNo" required autocomplete="off" />
                      </div>

                      <button type="submit" className="button button-block" >Save Changes</button>
                  </div>:
                    <div className="tab-content">
                          <div id="login">

                              <form action="/" method="post">

                                  <div className="field-wrap">
                                      <p>
                                          Old Password
                                      </p>
                                      <input type="password" required autocomplete="off" id="old" />
                                  </div>

                                  <div className="field-wrap input_icon">
                                      <p>
                                          New Password
                                      </p>
                                      <input type={ passwordShown? "text": "password" } required autocomplete="off" id="new" />
                                      <span onClick={togglePassword}>
                                        <img src='assets/dashboard_images/view.png' alt="icon" />
                                    </span>
                                  </div>

                                  <div className="field-wrap input_icon">
                                      <p>
                                          Confirm New Password
                                      </p>
                                      <input type={ passwordShown1? "text": "password" } required autocomplete="off" id="confirm" />
                                      <span onClick={togglePassword1}><img src='assets/dashboard_images/view.png' alt="icon" /></span>
                                  </div>
                                  <button className="button button-block" >Change Password</button>

                              </form>
                          </div>
                    </div>
                  }

              </div>

          </div>


    </div>
  )
}

export default EditAccount