import React from "react";
import "./buy_style.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useGlobalContext } from "../../context/DataContext";

const BuySubmit = (props) => {
  const { data } = props;

  const {
    setHandleNext,
    setHandleNext2,
    setDivHandle,
    setOpenWait,
  } = useGlobalContext();
  const navigate = useNavigate();

  
  const handleBack = () => {
    setDivHandle(false);
    setHandleNext(true);
    setHandleNext2(true);
    // console.log(divHandle);
  };

  const onSubmit = async () => {
    try {
      const json = {
        from: data.selectFrom,
        to: data.selectTo,
        make: data.selectCar,
        registerCity: data.personName,
        transmission: data.selectTrans,
        exteriorColor: data.extColor,
        minBudget: data.minBudget,
        maxBudget: data.maxBudget,
        urgency: data.selectAvailable,
        name: data.contact.name,
        email: data.contact.email,
        contactNo: data.contact.number,
        city: data.contact.city,
        address: data.contact.address,
        notes: data.contact.notes,
      };

      setOpenWait(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}cars/add-buy`,
        {
          data: json,
          headers: {
            "Content-Type": "application/json",
          },
        }

      );

      setOpenWait(false);
      navigate("/successfull");
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div>
        <section className="buySubmit">
          <div className="title-text">Review your request</div>
          <div
            className="container-fluid pl-3 wrap-text-111"
            id="request"
            style={{
              margin: "0px 50px",
            }}
          >
            <div className="wrapper">
              <div className="row mt-5 mb-4">
                <div className="col-lg-1 col-sm-2 text-danger p-0">STEP 1</div>
                <div
                  className="col-lg-11 col-sm-10 p-0"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  <b>Car Information</b>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-1 col-sm-12 p-0"> &nbsp; </div>
                <div className="col-lg-2 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Year</h4>
                    <p>
                      {data.selectFrom}- {data.selectTo}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Make</h4>
                    <p>
                      {data.selectCar.make}- {data.selectCar.model}-
                      {data.selectCar.version}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Registration City</h4> <p> {data.personName ? data.personName.join(", ") : ""}</p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Transmission</h4> <p> {data.selectTrans.toUpperCase()}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="row mt-5 mb-4">
                <div className="col-lg-1 col-sm-2 text-danger p-0">STEP 2</div>
                <div
                  className="col-lg-11 col-sm-10 pl-0"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  <b>Select color and budget</b>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-1 col-sm-12 pr-0"> &nbsp; </div>
                <div className="col-lg-5 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Exterior color</h4> <p> {data.extColor ? data.extColor.join(", ") : "" }</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Budget</h4>
                    <p>
                      <span className="pr-3">Min price: </span> {data.minBudget}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>&nbsp; </h4>
                    <p>
                      <span className="pr-3">Max price: </span> {data.maxBudget}
                    </p>
                  </div>
                </div>
                <div className="col-lg-1 col-sm-12 pr-0"> &nbsp; </div>
                <div className="col-lg-11 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Urgency</h4> <p> {data.selectAvailable}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper mt-3">
              <div className="wrapper">
                <div className="row mt-3 mb-4">
                  <div className="col-lg-1 col-sm-2 text-danger p-0">
                    STEP 3
                  </div>
                  <div
                    className="col-lg-11 col-sm-10 pl-0"
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    <b>Contact Information</b>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-1 col-sm-2 pr-0">&nbsp; </div>
                  <div className="col-lg-2 col-sm-12 p-0">
                    <div className="sell_submit_content_div">
                      <h4>Name</h4> <p> {data.contact.name}</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-12 p-0">
                    <div className="sell_submit_content_div">
                      <h4>Email</h4> <p> {data.contact.email}</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-12 p-0">
                    <div className="sell_submit_content_div">
                      <h4>Contact Number</h4>
                      <p> {data.contact.number}</p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-12 p-0">
                    <div className="sell_submit_content_div">
                      <h4>City</h4> <p> {data.contact.city}</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12 p-0">
                    <div className="sell_submit_content_div">
                      <h4>Address</h4> <p> {data.contact.address}</p>
                    </div>
                  </div>
                </div>
                <div className="row m-0 my-5 pt-4">
                <div className="col-lg-1 col-sm-2 pr-0">&nbsp; </div>
                  <div className="col-lg-11 col-sm-10 pl-0">
                    <div className="sell_submit_content_div">
                      <h4>Notes</h4> <p> {data.contact.notes}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0">
                <div className="col-lg-1 col-sm-2"></div>
                <div className="col-lg-11 mt-4 mb-4 border-bottom-1 p-0  pt-3 pb-4">
                  <span
                    className="text-col "
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Kindly review your request, as you won’t be able to make
                    changes once you submit. <br />
                  </span>
                  <p
                    className="pt-4"
                    style={{
                      fontSize: "12px !important",
                    }}
                  >
                    We’re excited you’re here and it would be an honour to serve
                    you. Please note that company reserves the right to charge
                    services i.e. 1% of the deal value. <br /> By clicking
                    Submit Request, By agreeing or submitting request, you agree
                    to comply with and be bound by these
                    <a
                      href="/"
                      style={{
                        fontSize: "14px !important",
                      }}
                    >
                      <span className="text-danger large"> terms</span>
                    </a>
                    , as applicable to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="sec-wrapper submit_bottom_div mt-3"
          style={{
            height: "120px",
          }}
        >
          <hr className="my-4 " />
          <div className=" container buy-submit-bottom">
            <div
              className=" display-wrapper"
              style={{
                paddingLeft: "454px",
              }}
            >
              <div className="d-flex nav-btns justify-content-end">
                <button
                  className="nav-btns-1 mr-3 text-white"
                  onClick={handleBack}
                  style={{
                    color: "white !important",
                    background: "#ed1c24",
                  }}
                >
                  Edit Information
                </button>
                <button
                  className="ms-2 nav-btns-2 text-white"
                  style={{
                    color: "white !important",
                    background: "#ed1c24",
                  }}
                  type="button"
                  onClick={onSubmit}
                >
                  Submit Request
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BuySubmit;
