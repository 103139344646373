import React, { useEffect, useState } from 'react'
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom"
import axios from 'axios';


const BuyData = (props) => {

 
    const { filter, searchBuyData } = props;
    const [employeeData,setEmployeeData]=useState({'_id':123123123,'carSaved':['6364adb77d1f62691241d592','63654834c7ee0e7af87255b9']})

    // const [carData, setCarData] = React.useState([]);
    //    let carInfo;

    // const getData = async () => {
    //     try {
    //         const res = await axios.get('${process.env.REACT_APP_API_URL}cars/all-buy');
    //         //console.log(res.data)
    //         return res.data.data;
    //     } catch (error) {
    //         console.log(error);
    //     }

    // }
    // // console.log('hello')
    // useEffect(() => {
    //     // console.log('useEffect');
    //     getData().then((data) => {
    //         // console.log(data);
    //         setCarData(data);
    //         //  console.log(carData);
    //     });
    // }, []);


    // Filter options 

    const [dummy, setDummy] = useState(searchBuyData);
    useEffect(() => {
        if (dummy !== searchBuyData) {
            setDummy(searchBuyData);
        }
    }, [])
    useEffect(() => {
        let len = -1;
        let len2 = -1;
        let len3 = -1;
        let len4 = -1;
        let len5 = -1;
        if (Object.keys(filter['city']).length > 0)
            len = Object.keys(filter['city']).findIndex((key) => filter['city'][key] === true);
        if (Object.keys(filter['make']).length > 0)
            len2 = Object.keys(filter['make']).findIndex((key) => filter['make'][key] === true);
        if (Object.keys(filter['color']).length > 0)
            len3 = Object.keys(filter['color']).findIndex((key) => filter['color'][key] === true);
        if (Object.keys(filter['year']).length > 0)
            len4 = Object.keys(filter['year']).findIndex((key) => filter['year'][key] === true);

        if (len >= 0 || len2 >= 0 || len3 >= 0 || (filter['budget'].max > -1 && filter['budget'].min > -1)) {
            setDummy(searchBuyData.filter((car) => {
                let cities = car.registerCity.findIndex((city) => filter['city'][city] === true);
                // console.log(cities);
                let colors = car.exteriorColor.findIndex((color) => filter['color'][color] === true);
                return ((cities > -1 || len === -1) && (filter['make'][car.make.make] === true || len2 === -1) && ((Number(car.maxBudget) <= filter['budget'].max && Number(car.minBudget) >= filter['budget'].min) || (filter['budget'].max === -1 && filter['budget'].min === -1)) && (colors > -1 || len3 === -1));
            }
            )
            )
        }
        else
            setDummy(searchBuyData);
    }, [searchBuyData, filter])

    // End of filter Options 

      // car Save query 

      const [saveCarIcon, setSaveCarIcon] = useState(false);

      const handleSave = async (id) => {

        const tokenId = localStorage.getItem('adminToken');
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_URL}cars/carsaveadmin?carid=' + id + '&id=636c94cda5864cd75a7aadc4`);
            // console.log(res);
            setSaveCarIcon(true);
            return res.data.data;
        } catch (err) {
            console.log(err);
        }
    }

    const SaveSubmit = (id, event) => {
        event.stopPropagation();
        handleSave(id).then((data) => {
            console.log(data);
        });
    }






    const sortedDummy = [...dummy].sort((a, b) => new Date(b.date) - new Date(a.date));

    const carInfo = sortedDummy.map((car) => {
        return car.active ? (
            <div className="d-flex align-items-center justify-content-center" key={car._id}>
                <Link to={"/buydetail/" + car._id}>
                    <div className="purchase_data buy_hover_div d-flex justify-content-between pb-4" style={{ borderBottom: "1px solid lightgrey", padding: "22px 0px" }}>
                        <div className="purchase_data_image" style={{ width: "40px", height: "40px", marginRight: "20px" }}>
                            <img src="assets/dashboard_images/alto.png" alt="suz" />
                        </div>
                        <div className="purchase_data_details" style={{ width: "315px" }}>
                            <h2 className="text-left d-inline pb-3">{car.make.make} {car.make.model}
                                <span> {car.from}</span> - <span>{car.to} </span>
                                <span> {car.make.version}</span> or <span>X</span>
                            </h2>
                            <div className="d-flex ">
                                <p className="text-left pr-2 purchase_data_city text-danger">
                                    <span>{car.minBudget}</span> - <span>{car.maxBudget}</span>
                                    <span style={{ fontWeight: "500", fontSize: "12px" }}> PKR</span>
                                </p>
                                <p className="text-left">{new Date(car.date).toLocaleString()}</p>
                            </div>
                        </div>

                        {/* High code  */}
                        <div className="purchase_data_second_content d-flex justify-content-center align-items-center" style={{ width: "80px" }}>
                            <p className="text-left text-danger text-uppercase" style={{ fontWeight: "600", letterSpacing: "0.12px" }}>
                                {car.urgency.split(' ').slice(0, 1).join(' ')}
                            </p>
                        </div>

                {/* High code  */}
                <div className="purchase_data_second_content d-flex justify-content-center align-items-center"
                    style={{ width: "80px" }}
                >
                    <p className="text-left text-danger text-uppercase "
                        style={{ fontWeight: "600", letterSpacing: "0.12px" }}
                    >{car.urgency.split(' ').slice(0, 1).join(' ')}</p>
                </div>

                {/* Purchase Cities  */}
                <div className="purchase_data_second_content purchase_data_km ml-3">
                    <img src="assets/dashboard_images/Group 2211.svg" className='mr-2 search_logo' alt='km' />
                    <p className="text-left">
                        {car.city}
                    </p>
                </div>
                <div className="purchase_data_second_content " style={{ width: "110px" }}>
                    <img src="assets/dashboard_images/auto.png" className='mr-2 search_logo' alt='km' />
                    <p className="text-left">{car.transmission}</p>
                </div>
                <div className="purchase_data_second_content purchase_data_color 
                                    d-flex justify-content-center align-items-center" style={{
                        width: "100px",
                        boxShadow: "0px 5px 10px #ded1d1"
                    }}>
                    {
                        car.exteriorColor.map((color, index) => {
                            return <span key={index} className='purchase_color1' style={{ backgroundColor: color }}></span>
                        })
                    }

                </div>
            </div> 
            </Link>
            <div className="sales_data_second_content " style={{ width: "30px", margin: "0px", zIndex: "1" }} >
            <button title="Save"
                onClick={(e) => SaveSubmit(car._id, e)}
                onDoubleClick={() => setSaveCarIcon(false)}
            >
                <img src={
                    employeeData.carSaved.indexOf(car._id)>-1 ? "assets/dashboard_images/Path 19264.png": "assets/dashboard_images/Path 19255.svg"  
                } className='mr-2 search_logo' alt='km' />
            </button>
                </div>
            </div>
        ) : null;
    });


    return (
        <div>
            {   dummy.length>0?carInfo: <div className="d-flex justify-content-center align-items-center mt-5" style={{height:"10vh", width:"60vw"}}>
                <h2 className='text-center'
                    style={{ color: "grey", fontWeight: "500", fontSize: "26px" }}
                >Sorry, No Data Found</h2>
            </div>}
        </div>
    )
}

export default BuyData