import React from 'react'
import axios from "axios";
import { createContext, useContext, useEffect, useState} from "react";

const AppContext  = createContext();


const AppProvider = ({ children }) => {

    // handleNavigation for Sell Car 
    const [handleNext, setHandleNext] = React.useState(true);
    const [handleNext2, setHandleNext2] = React.useState(true);
    const [divHandle, setDivHandle] = React.useState(false);

    // About Cars 

    const [activeDivSale, setActiveDivSale] = React.useState(true);
    const [activeDivBuy, setActiveDivBuy] = React.useState(false); 
    const [activeDivClose, setActiveDivClose] = React.useState(false);
    const [activeDivSaved, setActiveDivSaved] = React.useState(false);
    const [activeDivUsers, setActiveDivUsers] = React.useState(false);

     // Show numbers of sales cars 
  const [carData, setCarData] = useState([]);
  const getData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}cars/all-sell-cars`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            }
     });

    return res.data.data;

  }
  useEffect(() => {
    setTimeout(() => {
        getData().then((data) => {
            setCarData(data);
            console.log(data);
        });
        
    }
    , 5000);
  }, []);
     let totalSales= carData.filter(c=>c.active==true).length;

    //  End of sales cars 



    // Show numbers of buy cars
     const [buyData, setBuyData] = useState([]);
    const getBuyData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}cars/all-buy`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    }
            }
            );
            return res.data.data;
        } catch (error) {
            console.log(error);
        }

    }
    // console.log('hello')
    useEffect(() => {
        getBuyData().then((data) => {
            setBuyData(data);
        });
    }, []);
    let totalBuy = buyData.filter(c=>c.active==true).length;
    // console.log(totalBuy);
    // End of buy cars

    // Loading 
    const [openWait, setOpenWait] = React.useState(false);


    return <AppContext.Provider
                value={{ 
                    totalSales, totalBuy, 
                    carData, setCarData, 
                    buyData, setBuyData,
                    activeDivSale, setActiveDivSale, activeDivBuy, 
                    setActiveDivBuy,activeDivUsers, setActiveDivUsers,
                    activeDivSaved, setActiveDivSaved,activeDivClose, setActiveDivClose,
                    handleNext, setHandleNext, handleNext2, setHandleNext2,
                    divHandle, setDivHandle,
                    openWait, setOpenWait
                }}
            >
        {children}
    </AppContext.Provider>;
 }


 const useGlobalContext = () => {
    return useContext(AppContext);
}

export {  AppProvider,useGlobalContext };