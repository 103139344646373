import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button, Modal } from '@material-ui/core';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
        <h1>We are uploading your data, stay with us!</h1>
      <Box width="100%"  mr={1}>
        <LinearProgress variant="determinate" {...props}  
        style={{width:"60vw", height:"30px", background:"#ed1c24", borderRadius:"5px", marginTop:"15px"}}
        />
      </Box>
      <Box minWidth={100}>
        <Typography variant="body2" color="textSecondary" >{`${Math.round(
          props.value,
        )}%`}</Typography>

        <Button variant="contained" color="primary" style={{marginTop:"15px"}}  >Cancel</Button>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const ProgressAni = () => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={progress} />
    </div>
  );
}

export default ProgressAni;
