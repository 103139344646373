import { Slide } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useGlobalContext } from '../../../context/DataContext';
import "../dashboard_style.css"

const SavedRequest = () => {

    const [selectSaved, setSelectSaved] = React.useState(false);



    const handleChangeSaved = () => {
        setSelectSaved(true);
    }
    const handleChangeSaved1 = () => {
        setSelectSaved(false);
    }

    const [savedCar, setSavedCar] = React.useState([]);
    
    const [userId , setUserId] = React.useState(0);

    useEffect(() => {
        let itemdata = localStorage.getItem('user');
        let item = JSON.parse(itemdata);
        if(item){
            setUserId(item.data.id);
        }
    }, [])

    const getCarData = async () => {
        console.log(userId);
        const res = await axios.get(`${process.env.REACT_APP_API_URL}cars/carsavedbyuser?id=` + userId);
        console.log(res.data);
        if (res.status === 200) {
            return res.data;
        }
    }
    // console.log('hello')

    useEffect(() => {
        console.log('useEffect');
        getCarData().then((data) => {
            setSavedCar(data);
            //  console.log(carData);
        });
    }, []);

    const [totalSavedLength, setTotalSavedLength] = React.useState(0);
    useEffect(() => {
        setTotalSavedLength(savedCar.length);
    }, [savedCar])





    const { totalSales, totalBuy } = useGlobalContext();

    const carInfoSales = savedCar.map((car) => { return savedCar.length > 0 ? <div className="d-flex align-items-center justify-content-center">
        <div className="sales_data_wrapper col-12 p-0" >
            <div className="sales_data d-flex justify-content-between" >
                <div className="sales_data_image">
                    <img src="assets/dashboard_images/alto.png" alt="suz" />
                </div>
                <div className="sales_data_details" key={car._id}>
                    <h2 className="text-left d-inline">{car.make.make} {car.make.model} {car.make.year} </h2>-<span> {car.make.version}</span>
                    <div className="d-flex ">
                        <p className="text-left pr-2 sales_data_city">{car.registerCity}</p>
                        <p className="text-left">5 mins</p>
                    </div>
                </div>
                <div className="sales_data_second_content">
                    <p className="text-left text-danger text-uppercase "
                        style={{ fontWeight: "600", letterSpacing: "0.12px" }}
                    >{car.priority.split(' ').slice(0, 2).join(' ')}</p>
                </div>
                <div className="sales_data_second_content sales_data_km ml-3">
                    <img src="assets/dashboard_images/km.png" className='mr-2 search_logo' alt='km' />
                    <p className="text-left">{car.milage} km</p>
                </div>
                <div className="sales_data_second_content " style={{ width: "110px" }}>
                    <img src="assets/dashboard_images/auto.png" className='mr-2 search_logo' alt='km' />
                    <p className="text-left">{car.transmission}</p>
                </div>
                <div className="sales_data_second_content sales_data_color 
                            d-flex justify-content-center align-items-center" style={{ width: "100px", boxShadow: "0px 5px 10px #ded1d1" }}>
                    <span style={{
                        background: car.exteriorColor,
                    }} ></span>
                    <p className="text-left">{car.exteriorColor}</p>
                </div>


            </div>

        </div>
</div>: null })



  return (
    <div>
        <div className="container-fluid saved_request_wrapper">
            <div className="row">
                <div className="col-md-12">
                    <div className="dashboard_content">
                        <div className="dashboard_content_header">
                            <h3>Saved Request</h3>
                         </div>             
                    </div>
                </div>
            </div>     
        </div>
          <div className="dashboard_content_body saved_request_wrapper">
              <div className="row">
                  <div className="col-md-12 p-0">
                      <div className="saved_navigation">

                          <div 
                            className="d-flex"
                            style={selectSaved === false ? { borderBottom: "2px solid #ed1c24", color: "#000" }:null}
                              onClick={handleChangeSaved1}
                          >
                              <button className='mr-3 d-flex'
                                  style={selectSaved === false ? { color: "#000" } : null}
                              >
                                  <p className='mr-3 mb-0'>Cars for Sale</p>
                                  <span
                                    className={selectSaved === false ? "bg-danger" : null}
                                      style={{
                                          width: "auto",
                                          height: "23px",
                                          color: "#ffffff",
                                          fontSize: "14px",
                                          background: " #959494 0% 0% no-repeat padding-box",
                                          borderRadius: "5px",
                                          opacity: 1,
                                          padding: "1px 7px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center"
                                      }}



                                  >{totalSavedLength}</span>
                              </button>
                            </div>
                         
                          <div 
                            className="d-flex"
                              style={selectSaved === true ? { borderBottom: "2px solid #ed1c24", color: "#000" } : null}
                          >
                              <button className='mr-3 d-flex'
                                  onClick={handleChangeSaved}
                                  style={selectSaved === true ? { color: "#000" } : null}
                              >
                                  <p className='mr-3 mb-0'>Cars Purchase</p>
                                  <span
                                      className={selectSaved === true ? "bg-danger" : null}
                                      style={{
                                          width: "auto",
                                          height: "23px",
                                          color: "#ffffff",
                                          fontSize: "14px",
                                          background: " #959494 0% 0% no-repeat padding-box",
                                          borderRadius: "5px",
                                          opacity: 1,
                                          padding: "1px 7px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center"
                                      }}

                                      id="purchaseCount"

                                  >{totalBuy}</span>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
                 
        {
              selectSaved === false ? < div className="container purchase_dashboard_content dashboard_box sales_data_wrapper">
                  <div className="row" style={{ paddingTop: "10px" }}>
                      {/* Start of Side bar code  */}
                      <div className="col-lg-12 p-0" >
                          <div className="purchase_show_content">
                              <div className="bor-wrap">
                                  <div className="d-flex justify-content-end mb-2">
                                      <div className="sort-wrapper">
                                          Sort by <a href="/" className="text-danger">Date Posted</a>
                                      </div>
                                  </div>
                              </div>

                              {/* show all the data component  */}

                              <div className="row">
                                  {carInfoSales}
                              </div>
                          </div>
                      </div>
                  </div>
              </div> :

                  < div className="container purchase_dashboard_content dashboard_box purchase_data_wrapper">
                      <div className="row" style={{ paddingTop: "10px" }}>
                          {/* Start of Side bar code  */}
                          <div className="col-lg-12 p-0" >
                              <div className="purchase_show_content">
                                  <div className="bor-wrap">
                                      <div className="d-flex justify-content-end mb-2">
                                          <div className="sort-wrapper">
                                              Sort by <a href="/" className="text-danger">Date Posted</a>
                                          </div>
                                      </div>
                                  </div>

                                  {/* show all the data component  */}

                                  <div className="purchase_data_wrapper col-12 p-0">
                                    hello
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
        }

          
    </div>
  )
}

export default SavedRequest