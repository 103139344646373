import { Button, CircularProgress, Modal } from '@material-ui/core';
import React from 'react'

const LoadingComp = (props) => {
    const {open} = props;

    // const [openModal, setOpenModal] = React.useState(false);

    // const handleClose = () => {
    //     setOpenModal(false);
    // };

    // const handleOpen = () => {
    //     setOpenModal(true);
    // };

    return (
       <>
    
        <Modal
            sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh', width:'100vw'}}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'100vh', width:'100vw', background:"#ff1e1e9c"}}>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'auto', width:'20vw',color:"white", fontSize:"24px"}}>
                    <CircularProgress style={{
                        width:"100px", height:"100px" , color:"white"
                    }}/>
                    <p style={{marginTop:'20px'}}>Loading ... Stay with us</p>

                </div>
            </div>
        </Modal>
         </>
    )
}

export default LoadingComp;