import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div>
          <section className="page_404">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-12 ">
                          <div className="col-12 col-sm-offset-1  text-center">
                              <div className="four_zero_four_bg">
                                  <h1 className="text-center " style={{
                                        fontSize: "100px",
                                        fontWeight: "700",
                                        color: "#000",
                                        lineHeight: "100px",
                                  }}>404</h1>

                              </div>

                              <div className="contant_box_404">
                                  <h3 className="h2">
                                      Look like you're lost
                                  </h3>

                                  <p>the page you are looking for not avaible!</p>

                                  <Link to="/" className="link_404">Go to Home</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default ErrorPage