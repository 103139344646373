
import React, { useEffect } from 'react'

import "./admin_style.css";
import "../../App.css";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputLabel, OutlinedInput } from '@material-ui/core';
import axios from 'axios';



const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "auto",
        width: "700px !important",
    },
}));


const UsersArea = () => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [selectRole, setSelectRole] = React.useState("");
    const [selectPermission, setSelectPermission] = React.useState([]);
    const [showEmployee, setShowEmployee] = React.useState([]);


    const permissionNames = [
        'Add User',
        'Edit User Permission',
        'Delete User',
        'See all Sell Cars',
        'See all Buy Cars',
    ];

    // const ITEM_HEIGHT = 48;
    // const ITEM_PADDING_TOP = 8;
    // const MenuProps = {
    //     PaperProps: {
    //         style: {
    //             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //             width: 250,
    //         },
    //     },
    // };

    const handleChangeRole = (event) => {
        setSelectRole(event.target.value);
    };
    const handleChangePermission = (event) => {
        setSelectPermission(event.target.value);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const [openUpdate, setOpenUpdate] = React.useState(false);
    const handleUpdate = () => {
        setOpenUpdate(true);
    };
    const handleUpdateClose = () => {
        setOpenUpdate(false);
    };


    // Start of sending the data to the server 
    const [userValue, setUserValue] = React.useState({
        fullName: "",
        password: "",
        contactNumber: "",
        emailAddress: "",
    });
    const handleChangeUserValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserValue({ ...userValue, [name]: value });
    }
    const handleSaveEmployee = async () => {
        setOpen(false);

        try {
            var data = JSON.stringify({
                "fullName": userValue.fullName,
                "password": userValue.password,
                "contactNo": userValue.contactNumber,
                "email": userValue.emailAddress,
                "role": selectRole,
                "permissions": selectPermission
            });

            var config = {
                method: 'post',
                url:   `${process.env.REACT_APP_API_URL}admin/add-employee`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            }

            const res = await axios(config);
            // console.log(res.data);

        } catch (error) {
            console.log(error);
        }

    };

    // Show employee data 

    const showData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}admin/employees`);
            return res.data.data.employee;

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        showData().then((data) => {
            setShowEmployee(data);
        });

    }, []);

    // Delete Employee 
    const handleDelete = async (id) => {

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/deleteemployee/${id}`,
        };

        await axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                setOpenDelete(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    // Update Employee
    const [updateValue, setUpdateValue] = React.useState({
        fullNameUpdate: "",
        contactNumberUpdate: "",
    });

    const handleChangeUpdateValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUpdateValue({ ...updateValue, [name]: value });
    }


    const handleUpdateUser = async (id) => {

        const { fullNameUpdate, contactNumberUpdate } = updateValue;
        try {
            var data = JSON.stringify({
                "fullName": fullNameUpdate,
                "contactNo": contactNumberUpdate,
            });

            var config = {
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}admin/updateemployee/` + id,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            }
            if (updateValue.fullName === "" && updateValue.contactNumber === "") {
                return alert("Please fill the fields");

            }
            const res = await axios(config);
            // console.log(res.data);
            setOpenUpdate(false);

        } catch (error) {
            console.log(error);
        }

    }



    const mappingEmployees = showEmployee.map((employee) => {
        return <div className="users_data_wrapper col-12 p-0">
            <div className="users_data_table d-flex align-items-center justify-content-between">
                <div className="user_element user_name d-flex">
                    <p>{employee.fullName}</p>
                </div>
                <div className="user_element user_designation">
                    <p>{employee.role}</p>
                </div>
                <div className="user_element ">
                    <p>{employee.email}</p>
                </div>
                <div className="user_element ">
                    <p>{employee.contactNo}</p>
                </div>
                <div className="user_element  ">
                    <button className="action-button " type="button" onClick={handleUpdate}>
                        <i className="fa fa-pencil pr-3"></i>
                    </button>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openUpdate}
                        className={classes.modal}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openUpdate}>
                            <div className={classes.paper}
                                style={{
                                    backgroundColor: "white",
                                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="add_user_div">
                                    <div className="add_user_header">
                                        <h2>Update User</h2>
                                    </div>
                                    <div className="d-flex justify-content-between pt-3">
                                        <div className="">
                                            <div className="form-group">
                                                <label htmlFor="name">Full Name</label>
                                                <input type="text" className="form-control" id="name" placeholder="Enter user's full name"
                                                    onChange={handleChangeUpdateValues}
                                                    name="fullNameUpdate" value={updateValue.fullName}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-group">
                                                <label htmlFor="name">Password</label>
                                                <input type="password" className="form-control" id="password" placeholder="Nothing Here"
                                                    disabled
                                                    style={{ opacity: "0.4" }}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between py-2">
                                        <div className="">
                                            <div className="form-group">
                                                <label htmlFor="name">Contact Number</label>
                                                <input type="text" className="form-control" id="contactNo" placeholder="Enter user's contact number "
                                                    onChange={handleChangeUpdateValues}
                                                    name="contactNumberUpdate" value={updateValue.contactNumber}

                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-group">
                                                <label htmlFor="name">Email Address</label>
                                                <input type="text" className="form-control" id="email" placeholder="Enter user's email address"
                                                    disabled
                                                    style={{ opacity: "0.4" }}
                                                    value={employee.email}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col button-container mt-1 add_user_button pt-2">
                                        <button className="btn border border-top-0 p-2 px-3 m-2 cancel-button mx-4 fw-bolder"
                                            style={{ backgroundColor: "#ffebeb", borderRadius: "0px !important", fontWeight: "bold" }} onClick={handleUpdateClose}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-danger border border-0 text-light p-2 px-4 fw-bolder submit-btn"
                                            onClick={() => handleUpdateUser(employee._id)}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                    <button onClick={handleOpenDelete} >
                        <i className='fa fa-trash'></i>
                    </button>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openDelete}
                        className={classes.modal}
                        onClose={handleCloseDelete}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openDelete}>
                            <div
                                style={{
                                    backgroundColor: "white",
                                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="add_user_div">
                                    <div className="add_user_header d-flex justify-content-center align-items-center">
                                        <h3>Are you sure?</h3>
                                    </div>
                                    <div className="col button-container mt-1 add_user_button pt-2">
                                        <button className="btn border border-top-0 p-2 px-3 m-2 cancel-button mx-4 fw-bolder"
                                            style={{ backgroundColor: "#ffebeb", borderRadius: "0px !important", fontWeight: "bold" }} onClick={handleCloseDelete}
                                        >
                                            Cancel
                                        </button>
                                        <button onClick={() => handleDelete(employee._id)} className="btn btn-danger border border-0 text-light p-2 px-4 fw-bolder submit-btn">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                </div>
            </div>

        </div>
    });


    return (
        <div>
            <div className="container-fluid saved_request_wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="dashboard_content">
                            <div className="dashboard_content_header d-flex justify-content-between align-items-center">
                                <h3>Users</h3>
                                <button className="action-button " type="button" onClick={handleOpen}>
                                    <i className="fa fa-plus pr-2"></i>
                                    Add User
                                </button>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={open}
                                    className={classes.modal}
                                    onClose={handleClose}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={open}>
                                        <div className={classes.paper} >
                                            <div className="add_user_div">
                                                <div className="add_user_header">
                                                    <h2>Add New User</h2>
                                                </div>
                                                <div className="d-flex justify-content-between pt-3">
                                                    <div className="">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Full Name</label>
                                                            <input type="text" className="form-control" id="name" placeholder="Enter user's full name"
                                                                onChange={handleChangeUserValues}
                                                                name="fullName" value={userValue.fullName}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Password</label>
                                                            <input type="password" className="form-control" id="password" placeholder="Enter Employee's Password"
                                                                onChange={handleChangeUserValues}
                                                                name="password" value={userValue.password}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between py-2">
                                                    <div className="">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Contact Number</label>
                                                            <input type="text" className="form-control" id="contactNo" placeholder="Enter user's contact number "
                                                                onChange={handleChangeUserValues}
                                                                name="contactNumber" value={userValue.contactNumber}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Email Address</label>
                                                            <input type="email" className="form-control" id="email" placeholder="Enter user's email address"
                                                                onChange={handleChangeUserValues}
                                                                name="emailAddress" value={userValue.emailAddress}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="sel-wrapper d-flex flex-column">
                                                    <label htmlFor="lang">Role</label>
                                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                        <Select
                                                            value={selectRole}
                                                            onChange={handleChangeRole}
                                                            displayEmpty
                                                            className="list-dt-4"
                                                        >
                                                            <MenuItem value="">
                                                                <div className="d-flex flex-column">
                                                                    <p style={{ color: "#000" }}> Please select the User's Role</p>
                                                                </div>
                                                            </MenuItem>
                                                            <MenuItem value="moderator">
                                                                <div className="d-flex flex-column">
                                                                    <p> Moderator</p>
                                                                    <small>A moderator will have access to certain field whose access will be give by admin</small>
                                                                </div>
                                                            </MenuItem>
                                                            <MenuItem value="user">
                                                                <div className="d-flex flex-column">
                                                                    <p>User</p>
                                                                    <small>An user will only have access to view the sections</small>
                                                                </div>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {
                                                    selectRole === "moderator" ? <div className="sel-wrapper d-flex flex-column mt-2">
                                                        <label htmlFor="lang">Permissions</label>
                                                        <FormControl sx={{ m: 1, minWidth: 120 }} >
                                                            <InputLabel id="demo-multiple-name-label">Select the permission for moderator</InputLabel>
                                                            <Select
                                                                labelId="demo-multiple-name-label"
                                                                id="demo-multiple-name"
                                                                multiple
                                                                value={selectPermission}
                                                                onChange={handleChangePermission}

                                                                input={<OutlinedInput label="Name" />}
                                                            >
                                                                {permissionNames.map((name) => (
                                                                    <MenuItem
                                                                        style={{ paddingLeft: "50px" }}
                                                                        key={name}
                                                                        value={name}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div> : null
                                                }

                                                <div className="col button-container mt-1 add_user_button pt-2">
                                                    <button className="btn border border-top-0 p-2 px-3 m-2 cancel-button mx-4 fw-bolder"
                                                        style={{ backgroundColor: "#ffebeb", borderRadius: "0px !important", fontWeight: "bold" }} onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button onClick={handleSaveEmployee} className="btn btn-danger border border-0 text-light p-2 px-4 fw-bolder submit-btn">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End of modal code here  */}

            {/* start of User data table  */}

            < div className="container purchase_dashboard_content dashboard_box users_data_wrapper my-3">
                <div className="row">
                    {/* Start of Side bar code  */}
                    <div className="col-lg-10 " style={{ paddingLeft: "5rem" }}>
                        <div className="purchase_show_content">
                            <div className="bor-wrap">
                                <div className="d-flex justify-content-end mb-2">
                                    <div className="sort-wrapper">
                                        Sort by <a href="/" className="text-danger">Date Posted</a>
                                    </div>
                                </div>
                            </div>

                            {/* show all the users  */}

                            {mappingEmployees}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersArea