import React from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";


class CropImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: "",
            btnDis: true,
        };
        this.fileInput = React.createRef();
        this.handleFileRead = this.handleFileRead.bind(this);
        this.fileReader = new FileReader();
        this.cropper = React.createRef();
    }

    handleFileRead(e) {
        console.log("The reading is over");
        const binaryData = this.fileReader.result;
        const base64Data = window.btoa(binaryData);
        this.setState({ base64: base64Data });
    }

    handleChange(event) {
        const file = this.fileInput.current.files[0];
        const { name, size, type } = file;
        this.setState({
            name,
            size,
            type,
            croppedImgSrc: null
        });

        console.log(file.name);
        this.fileReader.onloadend = this.handleFileRead;
        this.fileReader.readAsBinaryString(file);
    }


    handleCropChange() {
        console.log("## cropped !");
        const croppedImgData = this.cropper.current.cropper
            .getCroppedCanvas()
            .toDataURL();
        this.setState({ btnDis: false, croppedImgSrc: croppedImgData });

    }
    handleSave(e) {
        e.preventDefault();
        let preview = this.props.preview;
        preview[this.props.index] = this.state.croppedImgSrc;
        console.log(preview);
        this.props.setPreview(preview)
        this.props.closeCropper(-1)
    }
    handleRotate() {
        this.cropper.current.cropper.rotate(90);
        this.handleCropChange();
    }

    render() {
        const { name, type, size, imageSrc, base64, croppedImgSrc } = this.state;

        return (
            <div style={{
                zIndex: 1, 
                position: "absolute",
                top: "10px",
                width: "1000px",
                background: "#404040",
                display: "flex",
                justifyContent: "center",
                padding: "0px",
                flexDirection: "column",
                boxShadow: "15px 10px 35px #7e7f818c",
            }}>
                <h1
                    style={{
                        textAlign: "center",
                        color: "black",
                        background: "white",
                        height: "60px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        paddingTop: "20px",
                    }}

                >Edit Photo</h1>
                <div style={
                    {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }
                }>
                    <Cropper
                        style={{ maxWidth: "600px", height: "400px", borderColor: "yellow" }}
                        ref={this.cropper}
                        src={this.props.selected}
                        aspectRatio={16 / 9}
                        cropend={() => this.handleCropChange()}
                    />
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "black",
                        height: "70px",
                        width: "100%",
                        
                    }}>
                        <button onClick={() => this.handleRotate()}
                            style={{
                                background:"white",
                                padding:"8px",
                                color:"black",
                                fontWeight: "500",
                            }}
                            type="button"
                        >
                           <img src="assets/dashboard_images/rotate.svg" alt="..." className="d-inline pr-2" /> 
                           Rotate
                        </button>
                    </div>
                    
                    <div style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        background: "white",
                        height: "70px",
                        width: "100%",
                        paddingRight: "20px",
                    }}>
                        <button onClick={() => this.props.closeCropper(-1)}
                        
                            style={{
                                background: "#e8ebee",
                                color: "black",
                                height: "40px",
                                width:"123px",
                                fontSize: "16px",
                                margin:"0px 15px",
                                fontWeight:"600",
                            }}
                        >Close</button>
                        <button 
                            onClick={(e) => this.handleSave(e)}
                            className="cropper_save_btn"
                            disabled={this.state.btnDis}
                            style={this.state.btnDis ? {
                                background:"grey"} : null}
                        >Save</button>
                    </div>


                </div>
            </div>
        );
    }
}
export default CropImages;

